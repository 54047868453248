import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

function ListCategory(props) {
  function handleCategoryClick(categoryId) {
    // console.log("!!!!!!!!");
    // console.log(event.target);
    // console.log(event.target.id);
    props.onCategoryClick(categoryId);
  }
  let { categoryList } = props;
  let { colorScheme } = props;
  return (
    <>
      {categoryList.map((category, index) =>
        category.rating != 1 ? (
          <div
            key={index}
            className={
              "col-8 bg-white  bigger-text text-center py-2 bg-my-" +
              colorScheme
            }
            // className={"btn-block btn-my" + colorScheme}
            // to="#"
            onClick={() => {
              handleCategoryClick(category.categoryId);
            }}
            // id={category.categoryId}
          >
            <div>
              <img
                className="div-product-image"
                src={
                  window.routerPrefix +
                  "/files/downloadImageFile/" +
                  category.image
                }
                alt=""
                onClick={() => {
                  handleCategoryClick(category.categoryId);
                }}
              />
            </div>
            <div
              onClick={() => {
                handleCategoryClick(category.categoryId);
              }}
            >
              {category.name}{" "}
            </div>
          </div>
        ) : null
      )}
    </>
  );
}
export default ListCategory;
