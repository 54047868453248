import React from "react";
import DropDownCategoryMenu from "./drop-down-category-menu";
import DropDownBrandMenu from "./drop-down-brand-menu";
import { Link } from "react-router-dom";
function SearchOptions(props) {
  // constructor(props) {
  //   super(props);
  // }
  function handleSpecialOffersClick() {
    props.onSpecialOffersClick();
  }
  function handleCategoryLinkClick(event) {
    props.onCategoryLinkClick(event);
  }
  function handleBrandLinkClick(event) {
    props.onBrandLinkClick(event);
  }
  let { categoryList } = props;
  let { brandList } = props;
  let { specialOffersList } = props;
  let { flagCategoryLinkClick } = props;
  let { flagBrandLinkClick } = props;
  // just check whether all categories are set to "0" for field "showcategory"
  let i,
    categoryCnt = 0,
    brandCnt = 0;
  for (let i = 0; i < categoryList.length; i++) {
    if (categoryList[i].rating != 1) {
      categoryCnt++;
    }
  } //for
  for (let i = 0; i < brandList.length; i++) {
    if (brandList[i].rating != 1) {
      brandCnt++;
    }
  } //for
  return (
    <>
      {categoryCnt >= 1 && categoryCnt <= 1 && !flagBrandLinkClick ? (
        <div className="col-4 col-md-2 p-0">
          <DropDownCategoryMenu
            categoryList={categoryList}
            onCategoryClick={props.onCategoryClick}
            title={"Search by Categories"}
            shortTitle={"Categories"}
          />
        </div>
      ) : null}

      {categoryCnt >= 2 && !flagBrandLinkClick && (
        <div className="small-text text-center search-by-category">
          {!flagCategoryLinkClick && (
            <Link to="#" className="btn" onClick={handleCategoryLinkClick}>
              Categories <i className="fas fa-bars"></i>
            </Link>
          )}
          {flagCategoryLinkClick && (
            <span className="text-thick ">Select a Category</span>
          )}
        </div>
      )}
      {/* search-by-category ends*/}
      {/* search-by-brand-starts */}
      {brandCnt >= 1 && brandCnt <= 2 && !flagCategoryLinkClick ? (
        <div className="col-4 col-md-2 p-0">
          <DropDownBrandMenu
            brandList={brandList}
            onBrandClick={props.onBrandClick}
            title={"Search by Brands"}
            shortTitle={"Brands"}
          />
        </div>
      ) : null}

      {brandCnt > 2 && !flagCategoryLinkClick ? (
        <div className="col-5 col-md-2 p-0 small-text text-center search-by-brand">
          {/* <Link to="#" className="btn" onClick={handleBrandLinkClick}>
            BRANDS {!flagBrandLinkClick && <i className="fas fa-bars"></i>}
          </Link> */}
          {!flagBrandLinkClick && (
            <Link to="#" className="btn" onClick={handleBrandLinkClick}>
              BRANDS <i className="fas fa-bars"></i>
            </Link>
          )}
          {flagBrandLinkClick && (
            <span className="text-thick  ">Select a Brand</span>
          )}
        </div>
      ) : null}

      {/* search-by-brand ends*/}
      {/* special-offers starts*/}
      {specialOffersList.length != 0 &&
        !flagCategoryLinkClick &&
        !flagBrandLinkClick && (
          <div className="column col-3 col-md-2 p-0">
            <button
              className="btn btn-block column p-0"
              onClick={handleSpecialOffersClick}
            >
              Offers
            </button>
          </div>
        )}
      {/* special-offers ends*/}
    </>
  );
}
export default SearchOptions;
