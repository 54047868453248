import React, { Component } from "react";
import { Link } from "react-router-dom";
class DropDownBrandMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleBrandClick = (event) => {
    this.props.onBrandClick(event);
  };
  render() {
    let { brandList } = this.props;
    let { title } = this.props;
    let { shortTitle } = this.props;
    return (
      <div className="nav-item dropdown ">
        <Link
          className="nav-link dropdown-toggle text-myblack"
          to="#"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/* <span className="normal-text">{title}</span> */}
          <span className="small-text">{shortTitle}</span>
        </Link>
        <div className="dropdown-menu" aria-labelledby="dropdown01">
          {brandList.map((brand, index) =>
            brand.rating != 1 ? (
              <Link
                className="dropdown-item"
                to="#"
                key={index}
                id={brand.brandId}
                onClick={this.handleBrandClick}
              >
                {brand.name}
              </Link>
            ) : null
          )}
        </div>
      </div>
    );
  }
}
export default DropDownBrandMenu;
