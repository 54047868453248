import React, { Component } from "react";
import { Link } from "react-router-dom";
class DropDownCategoryMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCategoryClick = (event) => {
    this.props.onCategoryClick(event);
  };
  render() {
    let { categoryList } = this.props;
    let { title } = this.props;
    let { shortTitle } = this.props;
    return (
      <div className="nav-item dropdown p-0">
        <Link
          className="nav-link dropdown-toggle text-myblack"
          to="#"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/* <span className="normal-text">{title}</span> */}
          <span className="small-text">{shortTitle}</span>
        </Link>
        <div className="dropdown-menu" aria-labelledby="dropdown01">
          {categoryList.map((category, index) =>
            category.rating != 1 ? (
              <Link //showCategory replaced by rating
                className="dropdown-item"
                to="#"
                key={index}
                id={category.categoryId}
                onClick={this.handleCategoryClick}
              >
                {category.name}
              </Link>
            ) : null
          )}
        </div>
      </div>
    );
  }
}
export default DropDownCategoryMenu;
