import React, { useEffect, useState } from "react";
import AdminProductList from "./admin-product-list";
import AdminProductAdd from "./admin-product-add";
import { ProductDB } from "../ProductDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { Link } from "react-router-dom";

function AdminProduct(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [flagTipsClick, setFlagTipsClick] = useState(false);
  let [tipsContent, setTipsContent] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let [productList, setProductList] = useState([]);
  let [productdb, setProductdb] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [product, setProduct] = useState(null);
  let [categoryToRetain, setCategoryToRetain] = useState("");
  let [categoryIdToRetain, setCategoryIdToRetain] = useState("");
  let [brandToRetain, setBrandToRetain] = useState("");
  let [brandIdToRetain, setBrandIdToRetain] = useState("");
  let [unitToRetain, setUnitToRetain] = useState("");
  let [unitIdToRetain, setUnitIdToRetain] = useState("");
  let [direction, setDirection] = useState("");
  let [sectionNumber, setSectionNumber] = useState("");
  let [sortedField, setSortedField] = useState("");

  useEffect(() => {
    init();
  }, []);
  function init() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .all([
        axios.get(window.routerPrefix + "/product/products/" + shopUrl),
        axios.get(window.routerPrefix + "/category/categories/" + shopUrl),
        axios.get(window.routerPrefix + "/brand/brands/" + shopUrl),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          let productList = res1.data;
          let categoryList = res2.data;
          let brandList = res3.data;
          // let productsLimit = res4.data[0].productsLimit; // only one shop
          // let languageChoice = res4.data[0].languageChoice;
          let updatedProducts = [...productList];
          productList.map((product, index) => {
            // get category (string) from categoryId
            for (let i = 0; i < categoryList.length; i++) {
              if (product.categoryId === categoryList[i].categoryId) {
                updatedProducts[index].category = categoryList[i].name;
                break;
              }
            }
            // get brand (string) from brandId
            for (let i = 0; i < brandList.length; i++) {
              if (product.brandId === brandList[i].brandId) {
                updatedProducts[index].brand = brandList[i].name;
                break;
              }
            } //for
            let unitList = [
              { unitId: 1, name: "Number" },
              { unitId: 2, name: "Multiple of 250 gm" },
              { unitId: 3, name: "Multiple of 1 kg" },
              { unitId: 4, name: "Multiple of 500 gm" },
              { unitId: 5, name: "Multiple of 1 kg" },
            ];
            // get unit (string) from unitId
            for (let i = 0; i < unitList.length; i++) {
              if (product.unitId === unitList[i].unitId) {
                updatedProducts[index].unit = unitList[i].name;
                break;
              } //if
            } //for
          }); //map
          productList = updatedProducts;
          setFlagLoad(false);
          setProductList(productList);
          setFilteredProducts(productList);
          setProductdb(new ProductDB(productList));
          setCategoryList(categoryList);
          setBrandList(brandList);
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDownloadClick() {
    JSONToCSVConvertor(filteredProducts, "", true);
  }
  function JSONToCSVConvertor(filteredProducts, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof filteredProducts != "object"
        ? JSON.parse(filteredProducts)
        : filteredProducts;
    var arrData = filteredProducts;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //condition will generate the Label/Header
    let headers = ["Name", "MRP", "Final Price", "In Stock", "Rating"];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated

        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        data = arrData[i][index];
        if (
          index === "productId" ||
          index === "nameMarathi" ||
          index === "information" ||
          index === "unitId" ||
          index === "unit" ||
          index === "categoryId" ||
          index === "category" ||
          index === "brandId" ||
          index === "brand" ||
          index === "searchWords" ||
          index === "image" ||
          // index === "rating" ||
          index === "dateAdded" ||
          index === "dateModified"
        ) {
          continue;
        }
        if (index === "name") {
          data = arrData[i]["name"] + "-" + arrData[i]["information"];
        } else if (index === "instock") {
          data = arrData[i]["instock"] ? "Yes" : "No";
        }
        row += '"' + data + '",';
      } //for
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let fileName = "Products  ";
    let dt = new Date();
    fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    //  + ".xls";
    //will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but will not work in some browsers
    // or you will not get the correct file extension

    //This trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //This part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    if (query.length == 0) {
      setFilteredProducts(productList);
      return;
    }
    let searchedProducts = [];
    // searchedProducts = productdb.filterByName(query); // removed 2023
    searchedProducts = productdb.filterByNameAndSearchWords(query); // added 2023
    setFilteredProducts(searchedProducts);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function onFormSubmit(product) {
    let message;
    let { action } = props;
    let { shopUrl } = props;
    setFlagLoad(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/product/update/" + shopUrl, product)
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error updating product
            message = "Error... could not update product.";
          } else {
            updateProductList(product);
            let message =
              "The product '" + product.name + "' updated successfully.";
            props.onCrudAction(message, "LIST", "Add a Product");
          }
        })
        .catch((err) => {
          setError(err);
          setFlagLoad(false);
          // props.askToLogin();
        });
    } else if (action === "ADD") {
      //ADD / POST
      product.image = "no.jpg"; // by default no image
      axios
        .post(
          window.routerPrefix + "/product/addProduct/" + props.shopUrl,
          product,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          }
        )
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error adding product
            message = "Something went wrong...";
            props.onCrudAction(message, "ADD"); // Add again
          } else {
            setCategoryToRetain(product.category);
            setCategoryIdToRetain(product.categoryId);
            setBrandToRetain(product.brand);

            setBrandIdToRetain(product.brandId);
            setUnitToRetain(product.unit);
            setUnitToRetain(product.unitId);
            product.productId = res.data; // id of the product is returned
            addToProductList(product);
            let message =
              "The product '" + product.name + "' added successfully.";
            props.onCrudAction(message, "ADD"); // Add again
          } //else
        })
        .catch((err) => {
          message = "Error... could not add product.";
          props.onCrudAction(message, "ADD"); // Add again
          setFlagLoad(false);
          setError(err);
        });
    } //else
  }
  function addToProductList(product) {
    let pList = [...productList];
    pList.unshift(product);
    let fProducts = [...filteredProducts];
    fProducts.unshift(product);
    setFilteredProducts(fProducts);
    setProductList(pList);
    setProductdb(new ProductDB(pList));
  }
  function updateProductList(product) {
    let pList = [...productList];
    for (let i = 0; i < productList.length; i++) {
      if (productList[i].productId == product.productId) {
        pList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        pList.unshift(product);
        break;
      }
    } //for
    // remove from filteredchapters also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < filteredProducts.length; i++) {
      if (filteredProducts[i].productId == product.productId) {
        // filteredSubjects[i] = subject;
        fProducts.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fProducts.unshift(product);
        break;
      }
    } //for
    //sorting
    setFilteredProducts(fProducts);
    setProductList(pList);
    setProductdb(new ProductDB(pList));
  }
  function handleEditProduct(product) {
    setProduct(product);
    // setSearchText("");
    props.onEditClick();
  }
  function handleDeleteProduct(product, result) {
    let message;
    if (result == 0) {
      //Error... deleting product
      message = "Error....could not delete the product";
      props.onCrudAction(message, "LIST");
      return;
    }
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    if (fProducts.length == 0) {
      // only one entity was searched and deleted, so search text should be removed, and all products should be shown
      setSearchText("");
      setFilteredProducts(pList);
    } else {
      setFilteredProducts(fProducts);
    }

    setProductdb(new ProductDB(pList));
    setProductList(pList);
    message = product.name + " deleted successfully";
    props.onCrudAction(message, "LIST");
  }
  function addDuplicatesToProductList(product, howMany, ids) {
    let pList = [...productList];
    let fProducts = [...filteredProducts];
    let dupliProduct;
    for (let i = 0; i < howMany; i++) {
      dupliProduct = {
        ...product,
        name: "D_" + product.name,
        productId: ids[i],
      };
      pList.unshift(dupliProduct);
      fProducts.unshift(dupliProduct);
    }
    setFilteredProducts(fProducts);
    setProductList(pList);
  }
  function handleDuplicateProduct(product, howMany) {
    setFlagLoad(true);
    axios
      .post(
        window.routerPrefix +
          "/product/duplicateProduct/" +
          props.shopUrl +
          "/" +
          howMany,
        product,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        let ids = res.data; // array of productId of products added
        setFlagLoad(false);
        let message;
        if (!ids || ids.length != howMany) {
          // error duplicating product
          message = "Error... could not create duplicate products";
        } else {
          let message = product.name + " duplicated " + howMany;
          if (howMany == 1) message += " time.";
          else message += " times.";
          props.onCrudAction(message, "LIST");
          addDuplicatesToProductList(product, howMany, ids);
        }
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredProducts(filteredProducts);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleTipsClick() {
    setFlagTipsClick(!flagTipsClick);
    if (flagTipsClick) return;

    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/files/downloadProductsTips")
      .then((res) => {
        setTipsContent(res.data);
        setFlagLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  let { action } = props;
  let { shopDetails } = props;
  let { colorScheme } = props;
  let { shopUrl } = props;
  let { subject } = props;
  let { help } = props;
  let content;
  let textShowHide;
  if (!flagTipsClick) {
    textShowHide = "";
  } else {
    textShowHide = "Hide ";
  }
  let downloadPanel = (
    <div className="container-fluid container-md fixed-bottom py-2 bg-mycyan text-center text-white">
      {/* <div className="container-fluid homepage-contact-details text-white text-center py-0"> */}
      <div className="col-10 col-md-2 mx-auto  p-0">
        <button className="btn-mycyan" onClick={handleDownloadClick}>
          Download Product Information
        </button>
      </div>
    </div>
  );
  if (flagLoad) {
    return  (
      <div className="container-fluid container-md container-list-table  p-0">
        <div className="row justify-content-center">
        <div className="col-12 text-center">
        <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
        </div>
      </div>
    );
  } 

  return (
    <div className="container-fluid container-md container-list-table  p-0">
      {action === "ADD" && productdb.size >=shopDetails.productsLimit && (
        <div classsName="row justify-content-center">
          <div className="col-12 text-center text-danger bigger-text">
            The store has reached the limit of {shopDetails.productsLimit}{" "}
            products. Go to Store-Settings to change the plan 
          </div>
          </div>
      )}
      {((action === "ADD" && productdb.size <shopDetails.productsLimit) ||action === "UPDATE" ) && (
      <div className="row  justify-content-center">
       <div className="col-12 text-center">
        <Link to="#" onClick={handleTipsClick}>
          {textShowHide} Important Tips
        </Link>
      </div>
      </div>
      )}
      {flagTipsClick && (
        <div className={"row justify-content-center mb-5 "}>
          <div className={"col-10  tips p-2 text-center border-all-my"+colorScheme}>
            <div className="div-center ">
              {tipsContent}
            </div>
          </div>
        </div>
      )}    
      { action == "LIST" && (<AdminProductList
        onEditProduct={handleEditProduct}
        onDeleteProduct={handleDeleteProduct}
        onSearchTextChange={handleSearchTextChange}
        onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
        onHeaderClick={handleHeaderClick}
        onSectionNumberClick={handleSectionNumberClick}
        onDuplicateProduct={handleDuplicateProduct}
        filteredProducts={filteredProducts}
        shopUrl={shopUrl}
        subject={subject}
        searchText={searchText}
        sectionNumber={sectionNumber}
        languageChoice={shopDetails.languageChoice}
        productsLimit={shopDetails.productsLimit}
        currentSize={productList.length}
        colorScheme={colorScheme}
      />)}
          {/* // After pressing Add Button, show only form and not the list */}
  { ( (action === "ADD" &&productdb.size <shopDetails.productsLimit  ) || action === "UPDATE" )&&
        <AdminProductAdd
          onFormSubmit={onFormSubmit}
          onCancelClick={handleCancelClick}
          product={product}
          action={action}
          categoryList={categoryList}
          brandList={brandList}
          categoryToRetain={categoryToRetain}
          categoryIdToRetain={categoryIdToRetain}
          brandToRetain={brandToRetain}
          brandIdToRetain={brandIdToRetain}
          unitToRetain={unitToRetain}
          unitIdToRetain={unitIdToRetain}
          languageChoice={shopDetails.languageChoice}
          colorScheme={colorScheme}
          help={help}
        />
  }
{action === "DELETE" && 
    <div>Delete action in Progress...</div>
  }
    </div>
  );
}
export default AdminProduct;
