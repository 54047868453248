import React from "react";
import { Link } from "react-router-dom";

function ListBrand(props) {
  function handleBrandClick(event) {
    props.onBrandClick(event);
  }
  function getBrandImageLink(brand) {
    let serverResponse = window.routerPrefix+"/files/downloadImageFile/" + brand.image;
    return serverResponse;
  }
  let { brandList } = props;
  let {colorScheme } = props;
  return (
    <React.Fragment>
      {/* <div className="row col-6 container-search-list column "> */}
      {brandList.map((brand, index) =>
        brand.rating != 1 ? (
          <div
            key={index}
            // className="col-6 col-md-4 bg-white column border-bottom bigger-text text-center py-2"
            className="col-6 col-md-4 bg-white column border-bottom bigger-text text-center py-2"
          >
            { (
              <Link // showCategory replaced by rating
                // className={"btn btn-block text-thick text-left p-0"}
                className={"btn-block btn-my" + colorScheme }
                to="#"
                onClick={handleBrandClick}
                id={brand.brandId}
              >
                {brand.name}
              </Link>
            )}

            {/* <Link to="#" onClick={handleBrandClick}>
              <LazyLoad>

                <img src={getBrandImageLink(brand)} />
              </LazyLoad>
            </Link> */}
          </div>
        ) : null
      )}
    </React.Fragment>
  );
}
export default ListBrand;
