import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import QuickProduct from "./quick-product";
import { Product } from "../product-class";
import { useState } from "react";

function ProductsQuickDisplay(props) {
  let flagShowImages = true;
  let [flagLoad, setFlagLoad] = useState(false);
  // let [flagOnlyAdded, setFlagOnlyAdded] = useState(false); 05.03.2025
  // let [flagOnlyAdded, setFlagOnlyAdded] = useState(false); 05.03.2025
  let { flagOnlyAdded } = props; // 05.03.2025
  let { flagOnlyFavourites } = props; // 05.03.2025
  let { flagOnlyDiscounted } = props; // 05.03.2025
  let { discountCnt } = props; // 05.03.2025
  let [favouriteList, setFavouriteList] = useState([]);
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);
  function getProductQuantity(id) {
    let i;
    for (i = 0; i < props.cartItems.length; i++) {
      if (id == props.cartItems[i].productId) {
        return props.cartItems[i].quantity;
      }
    }
    return 0;
  }
  function getProductFavouriteInfo(id) {
    let { userType } = props;
    if (userType === "GUEST") {
      // for Guest, no facility of favourite
      return false;
    }
    // let { favouriteList } = state;
    let i;
    for (i = 0; i < favouriteList.length; i++) {
      if (id === favouriteList[i]) {
        return true;
      } //if
    }
    return false;
  }

  function handleChangeProductQuantity(by, product) {
    props.onChangeProductQuantity(by, product);
  }
  function handleAddToCartItemList(product) {
    // Add this product to cart
    // this is original product obtained
    // copy it another product
    let multipleOf;
    if (product.unitId == 1) {
      // unit is numbers
      multipleOf = 1;
    } else if (product.unitId == 2) {
      // unit is 250 gms
      multipleOf = 0.25;
    } else if (product.unitId == 3) {
      // unit is kg
      multipleOf = 1;
    } else if (product.unitId == 4) {
      // unit is 500 gms
      multipleOf = 0.5;
    } else if (product.unitId == 5) {
      // unit is 10 kg and above
      multipleOf = 10; // start with 10kg only
    }

    let newProduct = new Product(
      product.productId,
      product.name,
      product.nameMarathi,
      product.information,
      product.mrp,
      product.finalPrice,
      multipleOf, //quantity,
      product.instock,
      product.unitId
    );
    // searchByProductId
    props.onAddToCartItemList(newProduct);
  }
  function handleFavouriteClick(productId, flagSetReset) {
    // Now this productId should be added to local storage
    // props.onFavouriteClick(productId);
    let { userType } = props;
    if (userType === "GUEST") {
      window.alert("Guest is not allowed to mark a product as favourite.");
      return false;
    }
    let { shopUrl } = props;
    let updatedFavouriteList = [...favouriteList];
    if (flagSetReset) updatedFavouriteList.push(productId);
    else {
      //remove from array
      for (var i = 0; i < updatedFavouriteList.length; i++) {
        if (updatedFavouriteList[i] === productId) {
          updatedFavouriteList.splice(i, 1);
          break;
        } //if
      } //for
    } //else
    window.localStorage.setItem(
      "favourite_" + shopUrl,
      JSON.stringify(updatedFavouriteList)
    );
    setFavouriteList(updatedFavouriteList);
    return true;
  }

  // componentDidMount = () => {
  function init() {
    setFlagLoad(true);
    // doInitialCalculation();
    // The user is regular user
    // So, get his/her favourites
    // if not create in local storage
    let { userType } = props;
    if (userType === "GUEST") {
      setFlagLoad(false);
      // no need to create favourite list in local storage
      return;
    }
    setFlagLoad(true);
    let { shopUrl } = props;
    let favouriteInfo = window.localStorage.getItem("favourite_" + shopUrl);
    if (!favouriteInfo) {
      // no favourites available, so create the one (ofcourse empty one)
      window.localStorage.setItem("favourite_" + shopUrl, JSON.stringify([]));
    } //if
    // First get data from favourite_shopUrl from local storage
    let favouriteList = JSON.parse(
      window.localStorage.getItem("favourite_" + shopUrl)
    );
    setFlagLoad(false);
    setFavouriteList(favouriteList);
  } //componentDidMount
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleOnlyFavouritesClick() {
    // setFlagOnlyFavourites(!flagOnlyFavourites);
    props.onOnlyFavouritesClick();
  }
  function handleOnlyAddedClick() {
    // setFlagOnlyFavourites(!flagOnlyFavourites);
    props.onOnlyAddedClick();
  }
  function handleOnlyDiscountedClick() {
    // setFlagOnlyFavourites(!flagOnlyFavourites);
    props.onOnlyDiscountedClick();
  }
  // function handleOnlyAddedClick() {
  //   setFlagOnlyAdded(!flagOnlyAdded);
  //   props.onFilterClick("FILTER", flagOnlyFavourites, !flagOnlyAdded);
  // }
  let { colorScheme } = props;

  let { selectedCategoryName } = props;
  let { selectedBrandName } = props;
  let { specialOffersList } = props;
  let { searchBy } = props;
  let { matchedFlag } = props;
  let { filteredProducts } = props;
  let { flagSearch } = props;
  let { flagCategoryLinkClick } = props;
  let { flagBrandLinkClick } = props;
  let { flagQuickMode } = props;
  let heading;
  let { productsPhoto } = props;
  let { informationPlace } = props;
  let { languageChoice } = props;
  let { cartItems } = props;

  let b;
  let { shopTypeId } = props;
  let classRow;
  let btnClassList = "btn-my" + colorScheme;
  // classRow =
  let repeated_products_display = (
    <div className="row mb-5">
      {filteredProducts.map((product, index) => (
        <QuickProduct
          product={product}
          productQty={getProductQuantity(product.productId)}
          productFavorite={getProductFavouriteInfo(product.productId)}
          onChangeProductQuantity={handleChangeProductQuantity}
          onAddToCartItemList={handleAddToCartItemList}
          flagShowImages={flagShowImages}
          key={product.productId}
          productsPhoto={productsPhoto}
          informationPlace={informationPlace}
          languageChoice={languageChoice}
          shopTypeId={props.shopTypeId}
          flagQuickMode={flagQuickMode}
          onFavouriteClick={handleFavouriteClick}
          flagOnlyFavourites={flagOnlyFavourites}
          flagOnlyAdded={flagOnlyAdded}
          flagOnlyDiscounted={flagOnlyDiscounted}
          // dummyPrice={props.dummyPrice}
          colorScheme={colorScheme}
        />
      ))}
    </div>
  );
  if (flagLoad)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={16} color={colorScheme} flagLoad />
      </div>
    );
  return (
    <>
      <div className="container-a-quick-products">
        {/* //  mycontainer ends */}

        {/* filter options end */}
        {searchBy !== "SPECIAL" &&
          !flagSearch &&
          !flagCategoryLinkClick &&
          !flagBrandLinkClick && (
            // flagSearch is true when user types something in the search text box
            <React.Fragment>{repeated_products_display}</React.Fragment>
          )}
        <div className="my-5">&nbsp;&nbsp;</div>
        {/* </div> */}
        {/* row ends */}
        {searchBy === "SPECIAL" && specialOffersList.length != 0 && (
          <div className="row justify-content-center ">
            {specialOffersList.map((specialOffer, index) => (
              <React.Fragment key={index}>
                <div className="col-6 text-center bg-mygray bigger-text mb-3">
                  <div className="text-center bg-mygrey text-thick mb-3">
                    {specialOffer.name}
                  </div>
                </div>
                <div className="col-10 bigger-text card-special-offer mb-3">
                  {specialOffer.description}
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {searchBy === "SPECIAL" && specialOffersList.length == 0 && (
          <div className="row justify-content-center ">
            <div>No Special offers yet.</div>
          </div>
        )}
      </div>
      {!flagSearch && !flagCategoryLinkClick && !flagBrandLinkClick && (
        <div
          className={
            "fixed-bottom mycontainer py-1 mx-auto text-center smaller-text border-all-my" +
            colorScheme +
            " bg-my" +
            colorScheme
          }
        >
          <div className="">
            Filter: &nbsp;
            <input
              type="checkbox"
              // className="form-check-input form-control-inline"
              checked={flagOnlyFavourites}
              onChange={handleOnlyFavouritesClick}
            />
            &nbsp;Favourites({favouriteList.length})&nbsp;
            <input
              type="checkbox"
              // className="form-check-input"
              checked={flagOnlyAdded}
              onChange={handleOnlyAddedClick}
            />{" "}
            Added({cartItems.length})&nbsp;
            <input
              type="checkbox"
              // className="form-check-input"
              checked={flagOnlyDiscounted}
              onChange={handleOnlyDiscountedClick}
            />{" "}
            Discounted({discountCnt})
          </div>
          <div className="">
            Arrange:&nbsp;
            <Link to="#" onClick={handleHeaderClick} id="name">
              Namewise
            </Link>{" "}
            &nbsp;|&nbsp;
            <Link to="#" onClick={handleHeaderClick} id="finalPrice">
              Pricewise
            </Link>
            &nbsp;|&nbsp;
            <Link to="#" onClick={handleHeaderClick} id="instock">
              Stockwise
            </Link>
          </div>
        </div>
      )}
      {/* row ends */}
    </>
  );
}
export default ProductsQuickDisplay;
