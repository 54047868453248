import React from "react";
export default function AboutUsCarousel(props) {
  let { shopUrl } = props;

  return (
    <>
      <div class="mycontainer mx-auto">
        <div
          id="carouselExampleInterval"
          className="carousel slide"
          // className="carousel fade"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-interval="2000">
              <img
                src={
                  window.routerPrefix +
                  "/files/downloadAboutUsImageFile/" +
                  shopUrl +
                  "/1"
                }
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-interval="2000">
              <img
                src={
                  window.routerPrefix +
                  "/files/downloadAboutUsImageFile/" +
                  shopUrl +
                  "/2"
                }
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item" data-interval="2000">
              <img
                src={
                  window.routerPrefix +
                  "/files/downloadAboutUsImageFile/" +
                  shopUrl +
                  "/3"
                }
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <div className="carousel-item" data-interval="2000">
            <img
              src={
                window.routerPrefix +
                "/files/downloadAboutUsImageFile/" +
                shopUrl +
                "/4"
              }
              className="d-block w-100"
              alt="..."
            />
          </div>
          <div className="carousel-item" data-interval="2000">
            <img
              src={
                window.routerPrefix +
                "/files/downloadAboutUsImageFile/" +
                shopUrl +
                "/5"
              }
              className="d-block w-100"
              alt="..."
            />
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleInterval"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleInterval"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </>
  );
}
