import React from "react";
import { useState } from "react";
import { FaStar } from "react-icons/fa";
import QuantityChanger from "./quantity-changer";

function QuickProduct(props) {
  let [message, setMessage] = useState("");
  function handleChangeProductQuantity(by) {
    let { product } = props;
    props.onChangeProductQuantity(by, product);
  }
  function handleAddToCartItemList(event) {
    event.preventDefault();
    let { product } = props;
    props.onAddToCartItemList(product);
  }
  function handleFavouriteClick(event) {
    let { productFavorite } = props;
    let { product } = props;
    let flag = props.onFavouriteClick(product.productId, !productFavorite);
    if (flag == false) {
      // user is guest, don't allow him
      return;
    }
    if (productFavorite) {
      setMessage("removed from favourite list");
      // this.setState({
      //   message: "removed from favourite list",
      //   flagMessage: true,
      // });
    } else {
      setMessage("added to favourite list");
      // this.setState({
      //   message: "added to favourite list",
      //   flagMessage: true,
      // });
    }
    window.setTimeout(() => {
      setMessage("");
    }, 1000);
  }
  let { product } = props;
  let { productQty } = props;
  let { productFavorite } = props;
  // let { flagMessage } = this.state;
  let { informationPlace } = props;
  // added marathi language feature on 27.08.2020
  // https://stackoverflow.com/questions/40628092/how-insert-marathi-using-php-and-mysql
  let { languageChoice } = props;
  let { shopTypeId } = props;
  // let { dummyPrice } = props;
  let { flagOnlyFavourites } = props;
  let { flagOnlyAdded } = props;
  let { flagOnlyDiscounted } = props;
  let { colorScheme } = props;
  let productDescription;
  let discount, productRate;
  if (product.mrp == product.finalPrice) {
    discount = 0;
  } else {
    discount = ((product.mrp - product.finalPrice) / product.mrp) * 100;
  }
  productDescription = <span>{product.name} </span>;

  // if (dummyPrice) {
  //   productRate = <span className=""> 1 (Dummy Price)</span>;
  // } else
  if (product.mrp == product.finalPrice) {
    productRate = <span className=""> {product.mrp}</span>;
  } else if (product.mrp != product.finalPrice) {
    productRate = (
      <>
        <span className="striked-text"> {product.mrp} </span>
        <span className=" ">&nbsp;{product.finalPrice}</span>
      </>
    );
  }
  let productUnit;
  if (product.unitId != 1) {
    {
      /* unitId 1 means product is measured in numbers */
    }
    productUnit = <span className="">/kg</span>;
  } //if
  else if (informationPlace == 0 && product.information) {
    productUnit = <span> ({product.information})</span>;
  } //else

  let classList =
    "row justify-content-center align-items-baseline p-2 mb-1 border-my" +
    colorScheme;

  let button;

  if (
    product.rating == 1 ||
    (flagOnlyFavourites == true && !productFavorite) ||
    (flagOnlyAdded == true && productQty == 0) ||
    (flagOnlyDiscounted == true && product.finalPrice == product.mrp)
  ) {
    // do not show products of rating =1
    // or products which are not favourites ( if "favourites only" filter applied)
    return null;
  }
  if (product.instock === 0) {
    button = (
      <button
        className="btn btn-block btn-dark"
        id={product.productId}
        disabled
      >
        Out of Stock
      </button>
    );
  } else if (productQty == 0) {
    button = (
      <button
        className="btn btn-block btn-warning"
        id={product.productId}
        onClick={handleAddToCartItemList}
      >
        Add to {shopTypeId != 8 ? "Yaadi" : "Cart"}
      </button>
    );
  } else if (productQty > 0) {
    button = (
      <QuantityChanger
        productQty={productQty}
        unitId={product.unitId}
        productId={product.productId}
        onChangeProductQuantity={handleChangeProductQuantity}
        title={"Qty "}
        shortTitle={"Qty "}
        action={"VIEW"}
        shopTypeId={props.shopTypeId}
        colorScheme={colorScheme}
      />
    );
  }
  let classes = "col-12 col-md-4 container-a-product ";
  if (productQty > 0) classes = classes + " carted-product";
  return (
    <>
      <div
        className="col-12 col-md-4 container-a-product"
        key={product.productId}
      >
        <div className={classList}>
          {/* <div className="col-7"> */}
          {/* <div className="col-7"> */}
          {/* <div className="row"> */}
          <div className="col-2 text-left p-0">
            {!message && (
              <label>
                <input
                  className="form-control-inline star-radio-buttons"
                  type="checkbox"
                  checked={productFavorite}
                  onChange={handleFavouriteClick}
                />
                <FaStar
                  className="star"
                  size={18}
                  color={productFavorite ? "red" : "grey"}
                />
              </label>
            )}
          </div>
          {/* col-2 ends */}
          {/* <div className="product-name p-0 text-right">          
            {discount != 0 && (
              <h6 className="quick-product-discount">
                {" "}
                {discount.toFixed(0)}% OFF
              </h6>
            )}
          </div> */}
          <div className="col-6">
            <div className="row justify-content-around align-self-baseline">
              <div className="col-12  p-0">{button}</div>
              <div className="col-12  text-center">
                {/* col-8 product-border column text-center */}
                {/* {productQty && !dummyPrice > 0 ? ( */}
                {productQty ? (
                  <span>
                    <i className="fas fa-rupee-sign"></i>{" "}
                    {product.finalPrice * productQty}
                  </span>
                ) : (
                  <span> &nbsp; </span>
                )}
              </div>
            </div>
            {/* row ends */}
          </div>
          {/* col-7 ends */}
          <div className="col-2 product-name p-0 text-right">
            {/* {discount != 0 && !dummyPrice && ( */}
            {discount != 0 && (
              <h6 className="quick-product-discount">
                {" "}
                {discount.toFixed(0)}% OFF
              </h6>
            )}
          </div>
          {message && (
            <div>
              {" "}
              (<span className="smaller-text text-danger">{message} </span>)
            </div>
          )}
          <div className="col-10 product-name p-0">
            {productDescription}{" "}
            {languageChoice && product.nameMarathi ? (
              <span className="text-thick">({product.nameMarathi})</span>
            ) : null}
          </div>
          <div className="col-10 p-0 column">
            <span className="text-thick">
              <i className="fas fa-rupee-sign"></i>
            </span>
            {productRate} {productUnit}
          </div>
        </div>
        {/* </div> */}
        {/* First colum of 6 size ends*/}
      </div>
      {/* </div> */}
    </>
  );
}
export default QuickProduct;
