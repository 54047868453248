import React, { Component } from "react";
import axios from "axios";
import NavBarOwner from "./navbar-owner";
import OwnerShop from "./owner-shop";
import OwnerShopType from "./owner-shoptype";
import OwnerCustomerData from "./owner-customer-data";
import OwnerAdminPassword from "./owner-admin-password";
import UploadImage from "./upload-image";
import OwnerLogReport from "./Owner-Log-Report";
class OwnerActivity extends Component {
  ownerManageList = [
    { name: "Shops", subject: "SHOP", type: 3 },
    { name: "Shop-Types", subject: "SHOPTYPE", type: 3 },
    { name: "Actual Customers Data", subject: "CUSTOMERDATA", type: 3 },
    { name: "Admin Passwords", subject: "ADMINPWD", type: 3 },
    { name: "Logs", subject: "LOGS", type: 3 },
    { name: "Logout", subject: "LOGOUT", type: 3 },
  ];
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      action: "LIST", // Initially show LIST, can be switched to ADD
      menu: "",
      message: "",
    };
  }
  handleOwnerLogOut = () => {
    this.props.onOwnerLogOut();
  };
  handleFormCancelClick = () => {
    this.setState({ subject: "" });
  };
  handleCancelClick = () => {
    window.scrollTo(0, 0); // moving scrollbar at the top
    // this.setState({ action: "LIST"});
    this.handleAddListToggleClick();
  };
  handleAddListToggleClick = () => {
    let { action } = this.state;
    let { subject } = this.state;
    let { menu } = this.state;
    if (action === "LIST") {
      action = "ADD";
      if (subject === "SHOP") {
        menu = "List of Shops";
      } else if (subject === "SHOPTYPE") {
        menu = "List of shop-types";
      }
    } //if
    else if (action === "ADD") {
      action = "LIST";
      if (subject === "SHOP") {
        menu = "Add a Shop";
      } else if (subject === "SHOPTYPE") {
        menu = "Add a shop-type";
      }
    } //... else if
    else if (action === "UPDATE") {
      action = "LIST";
      if (subject === "SHOP") {
        menu = "Add a Shop";
      } else if (subject === "SHOPTYPE") {
        menu = "Add a shop-type";
      }
    }
    this.setState({
      action: action,
      menu: menu,
      message: "",
    });
  };
  componentDidMount() {
    // Check whether session exists,
    window.scroll(0, 0);
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/owner/welcome")
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        let sessionExists = res.data;
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleOwnerMenuClick = (event) => {
    let index = event.target.id;
    let subject = this.ownerManageList[index]["subject"];
    this.setState({
      subject: subject,
      action: "LIST",
      message: "",
    });
    // for Customer-data, no facility of add, only edit
    if (subject === "CUSTOMERDATA" || subject === "ADMINPWD") {
      this.setState({
        menu: "",
      });
    } else {
      this.setState({
        menu: "Add a " + subject,
      });
    }
  };
  handleCrudAction = (message, action, menu) => {
    if (menu) {
      this.setState({ menu: menu });
    }
    this.setState({ message: message, action: action });
  };
  handleEditClick = () => {
    let { subject } = this.state;
    let { menu } = this.state;
    if (subject === "SHOP") {
      menu = "List of Shops";
    } else if (subject === "SHOPTYPE") {
      menu = "List of shop-types";
    }
    this.setState({ action: "UPDATE", menu: menu, message: "" });
  };
  render() {
    let { subject } = this.state;
    let { action } = this.state;
    let { menu } = this.state;
    let { message } = this.state;
    let content;
    if (subject === "SHOP") {
      content = (
        <OwnerShop
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onFormCancelClick={this.handleFormCancelClick}
          onCancelClick={this.handleCancelClick}
        />
      );
    } else if (subject === "SHOPTYPE") {
      content = (
        <OwnerShopType
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
        />
      );
    } else if (subject === "CUSTOMERDATA") {
      content = (
        <OwnerCustomerData
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
        />
      );
    } else if (subject === "LOGS") {
      content = (
        <OwnerLogReport
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
        />
      );
    } else if (subject === "ADMINPWD") {
      content = (
        <OwnerAdminPassword
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
        />
      );
    } else if (subject === "UPLOADIMAGE") {
      content = (
        <UploadImage
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
        />
      );
    }
    return (
      <>
        <NavBarOwner
          ownerManageList={this.ownerManageList}
          onOwnerMenuClick={this.handleOwnerMenuClick}
          onAddListToggleClick={this.handleAddListToggleClick}
          menu={menu}
          message={message}
        />
        {content}
      </>
    );
    //
  }
}
export default OwnerActivity;
