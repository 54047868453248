import React, { useState, useEffect } from "react";
import FooterAdvt from "./footer-advt";
import ShopInfo from "./shop-info";
import AboutUsImageSlider from "./about-us-image-slider";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import AboutUsCarousel from "./about-us-carousel";

function AboutUs(props) {
  let whatsApp;
  let [isAboutUsImage, setIsAboutUsImage] = useState(false);
  let [flagLoading, setFlagLoading] = useState(false);
  let [flagImage, setFlagImage] = useState(false);
  let [buttonLabel, setButtonLabel] = useState("Image Gallery");
  function handleStartShoppingClick() {
    props.onStartShoppingClick();
  }
  function handleStoreCreateClick() {}
  function handleContactNowClick() {
    let { whatsAppStore } = props;
    let whatsappNumber = "91" + whatsAppStore;
    let whatsAppMessage = "Hello...";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  }
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);
  let shopUrl = props.shopDetails.shopUrl;
  function init() {
    window.scroll(0, 0);
    // check whether about-us images are available
    //aboutUsImages

    let { dealer } = props;
    if (!dealer) return;
    setFlagLoading(true);
    // get data of the dealer
    let { shopDetails } = props;
    axios
      .get(
        window.routerPrefix +
          "/dealers/byLink/" +
          shopDetails.shopUrl +
          "/" +
          dealer
      )
      .then((res) => {
        whatsApp = res.data[0].whatsApp;
        setFlagLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoading(false);
      });
  }
  let { shopDetails } = props;
  let { settings } = props;
  let { colorScheme } = props;
  let { whatsAppStore } = props;

  let classList = "container container-md  fixed-top py-3 text-center ";
  classList += " bg-my" + props.colorScheme + "-dark";

  let btnClassList = "";
  btnClassList += "btn-my" + props.colorScheme; // + props.shopDetails.colorScheme;
  function handleImageGalleryClick() {
    setFlagImage(!flagImage);
  }
  if (flagLoading)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={14} color={props.colorScheme} flagLoading />
      </div>
    );
  return (
    <>
      <div className={classList}>
        <button onClick={handleStartShoppingClick} className={btnClassList}>
          START SHOPPING
        </button>
      </div>

      <div className="mycontainer mx-auto container-about-us ">
        {/* row starts - contains shop details */}
        <AboutUsCarousel
          shopUrl={shopDetails.shopUrl}
          status={shopDetails.status}
        />
        <div className={"row justify-content-center py-4 "}>
          <ShopInfo
            settings={settings}
            shopDetails={shopDetails}
            colorScheme={props.colorScheme}
            whatsAppStore={whatsAppStore}
          />
        </div>
        {/* row starts - contains an image  */}
        {/* <div className="row justify-content-center text-thick mt-1 pt-1">
          <div className={"col-10 col-md-8 text-center  mb-5 "}>
            <div className="text-center">
              <img
                className="div-aboutus-image"
                src={
                  window.routerPrefix +
                  "/files/downloadAboutUsImageFile/" +
                  shopUrl +
                  "/1"
                }
                alt="No Store Image"
              />
            </div>
          </div>
        </div> */}
        {/* row starts - contains text (about-us) */}
        {true && (
          <div className={"row justify-content-center my-2  "}>
            <div
              className={
                "col-12 col-md-8 about-us-text p-3  text-center border border-3 border-success "
              }
            >
              <div className="div-center  ">{shopDetails.aboutUs}</div>
            </div>
            {/* it took nearly 2 hours to find solution for new-line */}
            {/* the solution is css should be white-space: pre-line; */}
          </div>
        )}
      </div>
      {
        <FooterAdvt
          adminAsUser={props.adminAsUser}
          colorScheme={props.colorScheme}
        />
      }
    </>
  );
}
export default AboutUs;
