import React, { useEffect, useState } from "react";
import AdminPaymentImage from "./admin-payment-image";
function AdminAccount(props) {
  let [flagPaymentClicked, setFlagPaymentClicked] = useState(false);
  let [flagPaymentScreenShotUploaded, setFlagPaymentScreenShotUploaded] =
    useState(false);
  let { shopDetails } = props;
  let { colorScheme } = props;
  let paymentInfo = `
  Mobile Number: 9404431708
  Name: Mehul Jain
  UPI ID: mehuljain210200-1@okicici
  `;
  // let paymentInfo = `
  // UPI ID: mehuljain210200-1@okicici
  // Account Holder Name: Mehul Jain
  // Bank Account Number: 2212221541910685
  // IFSC code: AUBL0002215
  // `;

  let dateCreation = new Date(shopDetails.dateAdded);
  let dd = dateCreation.getDate();
  let mm = dateCreation.getMonth() + 1;
  let yy = dateCreation.getFullYear();
  let paymentDueDate = new Date(shopDetails.paymentDueDate);
  let StringDateOfPayment =
    paymentDueDate.getDate() +
    "/" +
    (paymentDueDate.getMonth() + 1) +
    "/" +
    paymentDueDate.getFullYear();
  useEffect(() => {
    //Runs only on the first render
    window.scroll(0, 0);
  }, []);
  function handlePaymentClick() {
    setFlagPaymentClicked(true);
  }
  function handleCancelScreenShotClick() {
    setFlagPaymentClicked(false);
  }
  function handleUploadPaymentScreenShot() {
    setFlagPaymentScreenShotUploaded(true);
    props.onUploadPaymentScreenShot();
  }
  return (
    <div className="row justify-content-center">
      <div className="col-12  text-center text-thick mb-4 bigger-text ">
        YOUR ACCOUNT
      </div>
      <div className="col-5  text-right text-thick mb-2 ">Store-Name:</div>
      <div className="col-7   ">{shopDetails.shopUrl}</div>
      <div className="col-5  text-right  text-thick mb-2">
        Store-creation Date:
      </div>
      <div className="col-7   ">
        {dd}/{mm}/{yy}
      </div>
      <div className="col-5  text-right  text-thick mb-2">Selected Plan</div>
      <div className="col-7   ">Plan-150 (Rs. 399/pm - max. 150 products)</div>
      {shopDetails.lastPaymentVerified == 1 && (
        <div className="col-5  text-thick text-right text-red mb-2">
          Next payment Due:
        </div>
      )}
      {shopDetails.lastPaymentVerified == 1 && (
        <div className="col-7  text-thick text-red">{StringDateOfPayment}</div>
      )}
      {shopDetails.lastPaymentVerified == 0 && (
        <div className="col-5  text-thick text-right text-red mb-2">
          Last payment :
        </div>
      )}
      {shopDetails.lastPaymentVerified == 0 && (
        <div className="col-7  text-thick text-red">Verification Pending</div>
      )}
      {shopDetails.lastPaymentVerified == 1 && (
        <div className="col-12  text-center my-3">
          <button
            className={"btn-my" + colorScheme}
            onClick={handlePaymentClick}
          >
            Pay due amount
          </button>
        </div>
      )}
      {flagPaymentClicked && !flagPaymentScreenShotUploaded && (
        <div className="col-10  text-center">
          We accept UPI payments (Gpay,PhonePe, etc.) only.
        </div>
      )}
      {flagPaymentClicked && !flagPaymentScreenShotUploaded && (
        <div className="col-10  text-center">
          <div className=" div-center payment-info ">{paymentInfo}</div>
        </div>
      )}
      {flagPaymentScreenShotUploaded && (
        <div className="col-12  text-center mb-2">
          <div className=" div-center payment-info ">
            Thanks for the payment. We will verify in 24 hrs.
          </div>
        </div>
      )}
      {flagPaymentClicked && !flagPaymentScreenShotUploaded && (
        <div className="col-10 text-center text-thick my-2 ">
          Once payment is done, upload screen-shot of the payment screen
        </div>
      )}
      {/* {flagPaymentClicked && <AdminPaymentAdd colorScheme={colorScheme}
        onFormCancelClick={handleFormCancelClick}
      />} */}
      {flagPaymentClicked && !flagPaymentScreenShotUploaded && (
        <AdminPaymentImage
          colorScheme={colorScheme}
          shopUrl={shopDetails.shopUrl}
          onCancelScreenShotClick={handleCancelScreenShotClick}
          onUploadPaymentScreenShot={handleUploadPaymentScreenShot}
        />
      )}
    </div>
  );
}
export default AdminAccount;
