import React, { useEffect, useState } from "react";
import AdminFaq from "./admin-faq";
import { Link } from "react-router-dom";
import axios from "axios";

function SdcAdminHomePageNew(props) {
  let [tandc, setTandc] = useState("");
  let [flagLoad, setFlagLoad] = useState(false);
  let [txtShowHide, setTxtShowHide] = useState("Show");
  let { selectedMenuIndex } = props;
  let { selectedMenuList } = props;
  let { selectedSubMenuIndex } = props;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  let [topicNumberClicked, setTopicNumberClicked] = useState(-1);
  let { colorScheme } = props;

  function handleFaqHeadingClick(topicNumber) {
    if (topicNumber == topicNumberClicked) {
      // clicked active heading
      setTopicNumberClicked(-1);
    } else {
      setTopicNumberClicked(topicNumber);
    }
  }
  function handleShowHideTandcCLick() {
    // get terms and conditions from back-end
    setFlagLoad(true);
    if (tandc) {
      setTxtShowHide("Show");
      setTandc("");
      return;
    }
    setTxtShowHide("Hide");
    axios
      .get(window.routerPrefix + "/files/downloadTandcFile")
      .then((res) => {
        setTandc(res.data);
        setFlagLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  function menuClicked(index) {
    props.onMenuClicked(index);
  }
  function subMenuClicked(subMenuIndex) {
    props.onSubMenuClicked(selectedMenuIndex, subMenuIndex);
  }
  let { mainMenus } = props;
  let task, pos;
  if (selectedMenuIndex != -1) {
    task = mainMenus[selectedMenuIndex].menu;
    pos = task.indexOf(" ");
    task = task.substring(0, pos);
  }

  return (
    <>
      <div
        className={
          "container-fluid container-md container-admin-home-page border-my" +
          colorScheme
        }
      >
        <div
          className={
            "text-center bigger-text text-thick text-italic text-black"
          }
        >
          {" "}
          {selectedMenuIndex != -1 && task}
        </div>
        {selectedMenuIndex == -1 &&
          mainMenus.map((e, index) => (
            <div className="row  justify-content-center" key={index}>
              <div
                onClick={() => {
                  menuClicked(index);
                }}
                className={
                  "col-5 div-clickable text-center text-thick bigger-text mb-2 p-2 bg-my" +
                  colorScheme
                }
              >
                {e.menu.toUpperCase()}
              </div>
            </div>
          ))}
        {/* container-ends */}
        {selectedMenuIndex >= 0 &&
          mainMenus[selectedMenuIndex].list.map((e, index) => (
            <div className="row  justify-content-center" key={index}>
              <div
                className={
                  "col-5 div-clickable text-center text-thick bigger-text mb-2 p-2 " +
                  (e.subject != "NONE" ? "bg-my" + colorScheme : "")
                }
                onClick={() => {
                  subMenuClicked(index);
                }}
              >
                {e.subject != "NONE" ? e.name.toUpperCase() : ""}
              </div>
            </div>
          ))}
        {/* container-ends */}
      </div>
      {selectedMenuIndex == -1 && (
        <div className="container-fluid container-md mt-3 ">
          {/* row-begins */}
          <div className="row"></div>
          <div className="row">
            <div className="col-8 col-md-6 text-center mx-auto homepage-image">
              <Link to="/">
                <img className="img-fluid" src="images/sdc_logo.jpg" alt="" />
              </Link>
            </div>
          </div>
          {/* row-begins */}
        </div>
      )}
    </>
  );
}
export default SdcAdminHomePageNew;
