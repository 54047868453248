import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import "../App.css";
import NavBar from "./navbar";
import Login from "./login";
import ProductsDisplay from "./products-display";
import ProductsQuickDisplay from "./products-quick-display";
import ShoppingCartDisplay from "./shopping-cart-display";
import AdminActivityProduct from "./admin-product";
import axios from "axios";
import { ProductDB } from "../ProductDB";
import FinalYaadiWhatsApp from "./final-yaadi-whatsapp";
import RepeatOrder from "./repeat-order";
import CustomerForm from "./customer-form";
import AboutUs from "./about-us";
import ThanksPage from "./thanks-page";
import HomePage from "./home-page";

class Home extends Component {
  shopUrl = "";
  dealerName = "";
  customer = null;
  yaadiURL = "";
  timeDelayForLogo = 1000;
  flagRepeatOrder = false; // This flag no longer needed. Date: 30.10.2020
  // Previously, it was thought of implementing
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userType: "",
      userJustLoggedIn: false,
      action: "",
      adminAction: "",
      prdb: {}, // Use of class 25.05.2020
      filteredProducts: [],
      error: null,
      cartItems: [],
      numberOfProducts: 0,
      totalAmount: 0,
      numberOfCartProducts: 0,
      initialized: false,
      matchedFlag: false,
      similarFlag: false,
      flagInvalidUrl: false,
      searchText: "",
      flagSearch: false,
      searchBy: "DEFAULT", //DEFAULT,TYPING, CATEGORY,BRAND, CLICK
      filterBy: "",
      shopDetails: {},
      colorScheme: "",
      settings: [],
      categoryList: [],
      specialOffersList: [],
      brandList: [],
      selectedCategoryId: "",
      selectedBrandId: "",
      flagLoading: true, // changed on 16.09.2020 to avoid loading of
      // 5 undefined_x.jpg images of image-gallery
      flagProcess: false,
      flagCartAdd: false,
      flagFinalStep: false,
      flagQuickMode: false,
      flagCategoryLinkClick: false,
      flagBrandLinkClick: false,
      flagInvalidPlaceOrder: false,
      isBilling: false,
      whatsAppStore: "",
      adminAsUser: false,
      flagOnlyAdded: false,
      flagOnlyFavourites: false,
      flagOnlyDiscounted: false,
      discountCnt: 0,
    };
  }
  handleAdminLogIn = () => {
    this.setState({
      userName: "ADMIN",
      userType: "ADMIN",
    });
  };
  handleAdminManageProducts = () => {
    this.setState({
      adminAction: "PRODUCTS",
    });
  };
  isProductfavourite = (productId) => {
    let { cartItems } = this.state;
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].productId == productId) {
        return true; // it is favourite
      } //if
    } //for
    return false; // it is not favourite
  };
  // Add an item to cartItems list
  clearFlagCartAdd = () => {
    this.setState({
      flagCartAdd: false,
    });
  };
  handleAddToCartItemList = (product) => {
    // The variable cartProduct contains complete product if it is VIEW mode
    // i.e. product added from  products-display page
    // otherwise it contains product added from CART mode
    // i.e. saved-for-later to yaadi
    this.setState({
      flagCartAdd: true,
    });
    setTimeout(this.clearFlagCartAdd, this.timeDelayForLogo);
    let cartItems = [...this.state.cartItems];
    // Product may be in the favourite list, check first
    if (this.isProductfavourite(product.productId)) {
      // yes favourite, so just make its quantity 1
      for (let i = 0; i < this.state.cartItems.length; i++) {
        if (this.state.cartItems[i].productId == product.productId) {
          cartItems[i].quantity = product.quantity;
          break;
        } //if
      } //for
    } //if... favourite
    else {
      //Not in favourite list, add to cart
      cartItems.push(product);
    } //else .... favourite
    // Product is already in the cart, just make its quantity 1
    this.setState({ cartItems: cartItems });
    this.updateLocalStorage(cartItems);
    this.calculateTotalAmount(cartItems);
  };

  handleDeleteFromCart = (product) => {
    // delete this product from the cartItems
    let cartItems = [];
    for (let i = 0; i < this.state.cartItems.length; i++) {
      if (this.state.cartItems[i].productId !== product.productId) {
        // In new list, this product should not be there
        cartItems.push(this.state.cartItems[i]);
      } //if
    } //for
    // Tried this to change state after 500 msec.
    // setTimeout( ()=>this.setState({ cartItems: cartItems }),500);
    this.setState({ cartItems: cartItems });
    this.updateLocalStorage(cartItems);
    this.calculateTotalAmount(cartItems);
  };

  removeNoStockProducts = (cartItems) => {
    //This removes no-stock products from cart
    if (this.flagRepeatOrder) {
      // its repeat order, so get products from this order
      // and add to cart
    }
    if (cartItems.length == 0) {
      return;
    }
    let cart = [];
    for (let i = 0; i < cartItems.length; i++) {
      let id = cartItems[i].productId;
      let product = this.state.prdb.searchByProductId(id);
      if (
        product == null ||
        product.instock == 0 ||
        product.rating == 1 ||
        cartItems[i].quantity == 0
      ) {
        // product either deleted by shopkeeper or
        // not in stock
        // or rating is 1 means is not displayed.
      } //if
      else {
        // One bug reported by Mane of puresattva
        // Shailesh Dhoka, previously stored items, old price was considered
        // so update the prices .
        cartItems[i].mrp = product.mrp;
        cartItems[i].finalPrice = product.finalPrice;
        cart.push(cartItems[i]); // previously, this was cart.push(product);
      }
    } // for
    this.setState({
      cartItems: cart,
    });
    window.localStorage.setItem(this.shopUrl, JSON.stringify(cart));
    // now update cart total amount
    this.calculateTotalAmount(cart);
  };
  calculateTotalAmount = (cartItems) => {
    let totalAmount = 0,
      numberOfCartProducts = 0,
      qty,
      finalPrice,
      id,
      mrp,
      unitId,
      amount;
    let product;
    for (let i = 0; i < cartItems.length; i++) {
      qty = cartItems[i].quantity;
      id = cartItems[i].productId;
      unitId = cartItems[i].unitId;
      // get latest price as the local storage contains old price
      product = this.state.prdb.searchByProductId(id);
      if (product != null) {
        mrp = product.mrp;
        finalPrice = product.finalPrice;
        amount = 0;
        if (unitId == 1) {
          // unit is in numbers
          amount = finalPrice * qty;
        } else if (unitId == 2 || unitId == 4) {
          // unit is in weight (multiple of 250 gms/500gms/100 gms)
          amount = finalPrice * qty;
        } else if (unitId == 3 || unitId == 5) {
          // unit is in weight (multiple of 1 kg)
          amount = finalPrice * qty;
        }

        totalAmount += amount;
        if (qty > 0) {
          numberOfCartProducts++;
        } //if
      } //if
      else {
        // meanwhile product is deleted by the admin (shopkeeper)
        // so delete from the local storage also
      }
    } //outer for
    this.setState({
      totalAmount: totalAmount,
      numberOfCartProducts: numberOfCartProducts,
    });
  };
  //numberOfCartProducts
  handleChangeProductQuantity = (by, product) => {
    // update quantity of the product in the cartItem list whose id is provided here
    let { action } = this.state;
    let cartItems = [...this.state.cartItems];
    for (let i = 0; i < this.state.cartItems.length; i++) {
      if (cartItems[i].productId == product.productId) {
        cartItems[i].quantity += by; // add by number equal to by

        if (cartItems[i].quantity <= 0) {
          if (action === "VIEW") {
            // remove the item from list
            cartItems.splice(i, 1);
          } //if...VIEW
          else if (action === "CART") {
            // put it at the end in the list, as it is favourite but not in cart
            let tempProduct = cartItems[i]; //.splice(i, 1); product used twice
            cartItems.splice(i, 1); // remove
            cartItems.push(tempProduct); // and add at the end
            //cartItems.push({ productId: productId, quantity: 0 });
          } //else ... CART
        } //if .... quantity==0
        this.updateLocalStorage(cartItems);
        this.calculateTotalAmount(cartItems);
        break;
      }
    }
    this.setState({ cartItems: cartItems });
  };
  handleQuickModeSelection = (flagQuickMode) => {
    this.setState({
      flagQuickMode: flagQuickMode,
    });
  };
  handleUserLogOut = () => {
    this.setState({
      userName: "",
      userType: "",
    });
  };
  handleAdminLogOut = () => {
    this.setState({ userName: "", userType: "" });
  };
  handleSearchBoxProductClick = (productId) => {
    let searchedProducts = [];
    let product = this.state.prdb.searchByProductId(productId);
    searchedProducts.push(product);
    this.setState({
      filteredProducts: searchedProducts,
      action: "VIEW",
      flagSearch: false,
      searchBy: "CLICK", // added 25062020
      flagOnlyAdded: false,
      flagOnlyFavourites: false,
      flagOnlyDiscounted: false,
    });
  };
  // handleFilterClick = (sb, flagOnlyFavourites, flagOnlyAdded) => {
  //   // props.onFilterClick("FILTER",flagOnlyFavourites,flagOnlyAdded);
  //   if (!flagOnlyFavourites && !flagOnlyAdded) {
  //     this.setState({
  //       searchBy: "DEFAULT",
  //     });
  //   } else {
  //     this.setState({
  //       searchBy: "FILTER",
  //     });
  //   }
  // };
  handleOnlyFavouritesClick = () => {
    this.setState({ flagOnlyFavourites: !this.state.flagOnlyFavourites });
    // if (!this.state.flagOnlyFavourites == false && this.state.flagOnlyAdded == false) {
    //   this.setState({ searchBy: "DEFAULT" });
    // } else {
    //   this.setState({ searchBy: "FILTER" });
    // }
  };
  handleOnlyAddedClick = () => {
    this.setState({ flagOnlyAdded: !this.state.flagOnlyAdded });
    // if (!this.state.flagOnlyAdded == false && this.state.flagOnlyFavourites == false) {
    //   this.setState({ filterBy: "" });
    // } else {
    //   this.setState({ filterBy: "FILTER" });
    // }
  };
  handleOnlyDiscountedClick = () => {
    this.setState({ flagOnlyDiscounted: !this.state.flagOnlyDiscounted });
  };

  handleSearchTextChange = (event) => {
    this.setState({ searchText: event.target.value });
  };
  handleSearchTextEnter = (event) => {
    // let searchText = event.target.value;
    this.setState({
      // searchText: searchText,
      action: "VIEW",
      flagSearch: false,
    });
    this.performSearchOperation();
    event.target.blur(); // leave the text now. so that
    // next time one focus again list is
  };
  handleSearchButtonClick = () => {
    // let searchText = event.target.value;
    this.setState({
      // searchText: searchText,
      action: "VIEW",
      flagSearch: false,
    });
    this.performSearchOperation();
  };
  handleCancelClick = () => {
    this.setState({
      searchText: "",
      action: "VIEW",
      flagSearch: false,
    });
  };
  handleShowAllClick = () => {
    this.setState({
      action: "VIEW",
      filteredProducts: this.state.prdb.allProducts(), //this.state.products
      searchBy: "DEFAULT",
      searchText: "",
      flagOnlyAdded: false,
      flagOnlyFavourites: false,
      flagOnlyDiscounted: false,
    });
  };
  handleSearchTextFocus = (event) => {
    this.setState({ flagSearch: true });
    this.setState({
      searchText: "",
    });
  };
  handleSearchTextBlur = (event) => {
    // this.setState({ flagSearch: false });
  };
  handleSearchTextBoxKeyUp = () => {
    this.performSearchOperation();
  };

  // this is dummy function used for testing purpose
  performSearchOperation1 = () => {
    let query = this.state.searchText.trim();
    if (query.length == 0) {
      this.setState({ searchBy: "DEFAULT" });
      // return;
    } else {
      this.setState({ searchBy: "TYPING" });
    }
    // query = query.toLowerCase();
    let searchedProducts = [];
    let matchedFlag, similarFlag;
    searchedProducts = this.state.prdb.filterByName(query);
    // searchedProducts = this.state.prdb.filterByNameAndSearchWords(query);
    if (searchedProducts.length === 0) {
      matchedFlag = false;
      searchedProducts = this.state.prdb.filterBySearchWords(query);
      if (searchedProducts.length === 0) {
        similarFlag = false;
      } else {
        similarFlag = true;
      }
    } else {
      matchedFlag = true;
    }
    this.setState({
      filteredProducts: searchedProducts,
      matchedFlag: matchedFlag,
      similarFlag: similarFlag,
    });
  };
  performSearchOperation = () => {
    let query = this.state.searchText.trim();
    if (query.length == 0) {
      this.setState({ searchBy: "DEFAULT" });
      // return;
    } else {
      this.setState({ searchBy: "TYPING" });
    }
    // query = query.toLowerCase();
    let searchedProducts = [];
    let matchedFlag, similarFlag;

    // searchedProducts = this.state.prdb.filterByName(query);
    searchedProducts = this.state.prdb.filterByNameAndSearchWords(query);
    if (searchedProducts.length === 0) {
      matchedFlag = false;
      searchedProducts = this.state.prdb.filterBySearchWords(query);
      if (searchedProducts.length === 0) {
        similarFlag = false;
      } else {
        similarFlag = true;
      }
    } else {
      matchedFlag = true;
    }
    this.setState({
      filteredProducts: searchedProducts,
      matchedFlag: matchedFlag,
      similarFlag: similarFlag,
    });
  };
  // handleCheckout
  handleCartClick = () => {
    // if (this.state.isBilling) {
    //   this.handlePlaceorderClick();
    //   return;
    // }
    this.setState({
      action: "CART", // Show cart contents
    });
  };
  handleLogoClick = () => {
    // customer's data might have been modified
    this.getCustomerInfo();
    let { shopDetails } = this.state;
    if (shopDetails.homePageFacility == 1) {
      this.setState({
        action: "ABOUTUS",
      });
    } else {
      this.setState({
        action: "CUSTOMERFORM",
      });
    }
    // this.setState({
    //   action: "ABOUTUS", // Show about us contents
    // });
  };
  handleLogInClick = () => {
    this.setState({
      action: "LOGIN",
    });
  };
  handleCategoryClick = (categoryId) => {
    let selectedCategoryId = categoryId; //event.target.id;
    console.log(selectedCategoryId + ".......");
    // Get name of category
    let { categoryList } = this.state;
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].categoryId == selectedCategoryId) {
        this.selectedCategoryName = categoryList[i].name;
        break;
      } //if
    } //for
    //this.selectedCategoryName = event.target.innerText;
    // Above line was used when no images were there for categories (09.10.2020)
    // this.state.searchText = ""; 24062020
    let searchedProducts = this.state.prdb.filterByCategory(selectedCategoryId);
    this.setState({
      filteredProducts: searchedProducts,
      searchBy: "CATEGORY",
      selectedCategoryId: selectedCategoryId,
      searchText: "",
      flagCategoryLinkClick: false,
      flagOnlyAdded: false,
      flagOnlyFavourites: false,
      flagOnlyDiscounted: false,
    });
  };
  handleCategoryPanelClose = () => {
    this.setState({
      flagCategoryLinkClick: false,
    });
  };
  handleBrandPanelClose = () => {
    this.setState({
      flagBrandLinkClick: false,
    });
  };
  handleCategoryLinkClick = (event) => {
    this.setState({
      flagCategoryLinkClick: true,
    });
    this.setState({
      // filteredProducts: searchedProducts,
      // searchBy: "CATEGORY",
      // selectedCategoryId: selectedCategoryId,
      searchText: "",
    });
  };
  handleBrandLinkClick = (event) => {
    this.setState({
      flagBrandLinkClick: true,
    });
    this.setState({
      // filteredProducts: searchedProducts,
      // searchBy: "CATEGORY",
      // selectedCategoryId: selectedCategoryId,
      searchText: "",
    });
  };
  handleBrandClick = (event) => {
    let selectedBrandId = event.target.id;
    // Get name of category
    let { brandList } = this.state;
    for (let i = 0; i < brandList.length; i++) {
      if (brandList[i].brandId == selectedBrandId) {
        this.selectedBrandName = brandList[i].name;
        break;
      } //if
    } //for
    // this.selectedBrandName = event.target.innerText;
    // Above line was used when no images were there for brands (09.10.2020)
    // this.state.searchText = ""; 24062020

    // this.searchText = "";
    let searchedProducts = this.state.prdb.filterByBrand(selectedBrandId);
    this.setState({
      filteredProducts: searchedProducts,
      searchBy: "BRAND",
      selectedBrandId: selectedBrandId,
      searchText: "", //24062020
      flagBrandLinkClick: false,
      flagOnlyAdded: false,
      flagOnlyFavourites: false,
      flagOnlyDiscounted: false,
    });
  };
  handleSpecialOffersClick = (event) => {
    this.setState({
      searchBy: "SPECIAL",
    });
  };
  // handleLogoClick = () => {
  //   this.setState({
  //     filteredProducts: this.state.prdb.allProducts(), //this.state.products,
  //     searchBy: "DEFAULT",
  //     action: "VIEW",
  //   });
  //   this.searchText = "";
  // };
  // addCartInfoToFilteredProduct() {}

  handleBackClick = () => {
    if (this.state.isBilling && this.state.action == "CART") {
      this.setState({
        action: "VIEW",
      });
      return;
    }
    if (this.state.isBilling && this.state.action == "FINALYAADI") {
      this.setState({
        action: "CART",
      });
      return;
    }
    this.setState({
      action: "VIEW",
    });
  };
  handleStartShoppingClick = () => {
    this.setState({
      action: "CUSTOMERFORM",
    });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredProducts } = this.state;
    if (direction == false) {
      //in ascending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredProducts: filteredProducts, sortedField: field });
  };
  handleCustomerFormSubmit = (customer) => {
    this.setState({
      customer: customer,
    });
    window.localStorage.setItem("customer", JSON.stringify(customer));
    this.getProductsDetailsFromDatabase();
    let repeatOrder = window.localStorage.getItem(this.shopUrl + "_1");
    if (!repeatOrder) {
      // no previous order, so directly go to "VIEW" mode and display products
      this.setState({
        action: "VIEW",
        userType: "REGULAR",
      });
    } else {
      // show an extra page to ask for repeat order
      this.setState({
        // action: "VIEW",
        action: "REPEATORDER",
        userType: "REGULAR",
      });
    }
  };
  handleProceedAsGuestClick = () => {
    this.setState({
      action: "VIEW",
    });
    this.getProductsDetailsFromDatabase();
  };
  makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  handleWhatsappButtonClick = () => {
    // when customer clicks to whatsapp button, data is stored in server-database
    // first yaadi, here yaadiUrl, yaadiId is obtained
    // then yaadiproducts are stored in database using yaadiId
    // then user is passed to whatsapp with yaadiUrl
    this.postYaadiToServer();
  };
  handlePrintBillClick = () => {
    let customer = JSON.parse(window.localStorage.getItem("customer"));
    this.setState({ customer: customer });
    this.postYaadiToServer();
  };
  handlePlaceorderClick = () => {
    // Also Diyya Patnaik of Puresattva on 27.08.2020 smartly did
    // without entering mobile number and address, so let us check
    // personal details from local storage again
    if (this.state.isBilling) {
      this.setState({
        action: "FINALYAADI",
      });
      return;
    }
    let customerInfo = JSON.parse(window.localStorage.getItem("customer"));
    if (
      customerInfo.name.length < 6 ||
      customerInfo.address.length < 10 ||
      customerInfo.whatsApp.length != 10
    ) {
      if (window.alert("Enter your details first.")) {
        this.setState({
          action: "CUSTOMERFORM",
        });
      }
      this.handleDeleteAllItems(); // Saja (Shiksha :) Ha Ha Ha...)
      return;
    }
    let minimumAmount = this.state.settings.minimumAmount;
    let minimumCriteria = this.state.settings.minimumCriteria;
    // Not done for total weight yet
    // if (minimumCriteria == 1 && this.state.cartItems.length < minimumAmount) {
    // window.alert("Minimum " +minimumAmount +" items should be purchased");
    // return;
    // } //if
    // Checked all conditions... now final yaadi
    // Just for trial... add to table info whether quick mode is used or not
    // 17.09.2020
    //ADD / POST

    let mode = this.state.flagQuickMode ? 1 : 0;
    let data = {
      name: this.state.customer.name,
      shopUrl: this.shopUrl,
      noOfItems: this.state.cartItems.length,
      quickMode: mode,
    };
    // Quick mode info is cancelled 2023
    // this.setState({
    //   flagLoading: true,
    // });
    // axios
    //   .post("/quickModeInfo", data, {
    //     headers: {
    //       accept: "application/json",
    //       "Accept-Language": "en-US,en;q=0.8",
    //     },
    //   })
    //   .then((res) => {
    //     this.setState({
    //       response: res.data,
    //       flagLoading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("error...");
    //     this.setState({
    //       flagLoading: false,
    //     });
    //   });
    this.setState({
      action: "FINALYAADI",
    });
  };
  postYaadiToServer = () => {
    // data should be submitted to server
    //1. table yaadies
    // yaadiId, yaadiUrl,
    // customerName, whatsappNumber, customerAddress, totalAmount, dateOfYaadi
    this.setState({
      flagLoading: true,
    });
    let { shopDetails } = this.state;
    // let { customer } = this.state;// Changed for billing
    let customer = JSON.parse(window.localStorage.getItem("customer"));
    let { settings } = this.state;
    var yaadiId;
    // let cname = this.customer.name.replace(" ", "");
    let d = new Date();
    this.yaadiUrl =
      // cname +
      this.pad(d.getHours(), 2) +
      this.pad(d.getMinutes(), 2) +
      this.pad(d.getSeconds(), 2) +
      this.makeid(4) + // makeid returns random string
      this.pad(d.getDate(), 2) +
      this.pad(d.getMonth() + 1, 2) +
      "_" +
      shopDetails.shopUrl;
    // if(this.dealer)
    // this.yaadiUrl+="_"+dealer;

    let yaadi = {};
    yaadi.yaadiUrl = this.yaadiUrl;
    // yaadi.shopUrl = shopDetails.shopUrl;
    yaadi.customerName = customer.name;
    yaadi.customerWhatsApp = customer.whatsApp;
    yaadi.customerAddress = customer.address;
    let { totalAmount } = this.state;
    yaadi.totalAmount = totalAmount;
    if (
      totalAmount < settings.deliveryChargesBelow &&
      settings.deliveryCharges > 0
    ) {
      yaadi.deliveryCharges = settings.deliveryCharges;
    } else {
      yaadi.deliveryCharges = 0;
    }
    // 2023 -> promo discount
    yaadi.promoDiscount = 0;
    // 2023 -> net amount
    yaadi.netAmount =
      Number(yaadi.totalAmount) +
      Number(yaadi.deliveryCharges) -
      Number(yaadi.promoDiscount);
    yaadi.dealer = this.dealer;
    //ADD / POST

    // post this yaadi to server
    axios
      .post(
        window.routerPrefix + "/yaadi/addYaadi/" + shopDetails.shopUrl,
        yaadi,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        this.setState({
          response: res.data,
        });
        yaadiId = res.data; // id of inserted yaadi received
        // this id is -1 if without session tried, so its supsicious
        // In such case, page of CUSTOMERFORM should be shown.
        if (yaadiId == 0) {
          window.alert("Something went wrong...Try again after some time");
          this.setState({ action: "CUSTOMERFORM", flagLoading: false });
          return;
        } else {
        }
        // Pass this yaadiId to yaadiProducts table
        this.postYaadiProductsToServer(yaadiId, this.yaadiUrl, yaadi);
      })
      .catch((err) => {
        console.log("error...1");
        this.setState({
          flagLoading: false,
        });
      });
  };
  postYaadiProductsToServer = (yaadiId, yaadiUrl, yaadi) => {
    // data should be submitted to server
    // table yaadiProducts
    // from cartItems
    //description, quantity, unit, mrp, finalPrice
    let { shopDetails } = this.state;
    let { settings } = this.state;
    let { numberOfCartProducts } = this.state;
    let { totalAmount } = this.state;
    let yaadiProducts = [];
    let cartItems = this.state.cartItems;
    cartItems.map((product, index) => {
      if (product.quantity > 0) {
        let yaadiProduct = new Object(); // This is imp
        yaadiProduct.productId = product.productId;
        yaadiProduct.name = product.name;
        yaadiProduct.nameMarathi = product.nameMarathi;
        yaadiProduct.information = product.information;
        yaadiProduct.quantity = product.quantity;
        yaadiProduct.unitId = product.unitId;
        yaadiProduct.mrp = product.mrp;
        yaadiProduct.finalPrice = product.finalPrice;
        yaadiProduct.yaadiId = yaadiId;
        yaadiProducts.push(yaadiProduct);
      }
    });
    //ADD / POST
    // post this yaadi to server
    axios
      // .post("/yaadiProducts/" + shopDetails.shopUrl, yaadiProducts, {
      .post(
        window.routerPrefix +
          "/yaadiProduct/addYaadiProducts/" +
          shopDetails.shopUrl,
        yaadiProducts,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          response: res.data,
          flagLoading: false,
        });
        let whatsappNumber = "91" + String(this.state.whatsAppStore);
        let shopTypeId = shopDetails.shopTypeId;
        let whatsAppMessage;
        // if (this.state.settings.dummyPrice) {
        //   // Price is Rs. 1/-. Made for wholesalers. 17.11.2020
        //   whatsAppMessage = `Hello, I am ${this.customer.name}. Here is my Yaadi. It contains ${numberOfCartProducts} items. Link for yaadi is www.meriyaadi.com/yaadilist/${yaadiUrl}`;
        // } else

        let finalAmount, words;
        let { deliveryCharges } = yaadi; // calculated already
        if (deliveryCharges > 0) {
          finalAmount = Number(totalAmount) + Number(deliveryCharges);
          words =
            " (Including delivery charges, Rs. " + deliveryCharges + "/-)";
        } else {
          finalAmount = totalAmount;
          words = "";
        }

        if (shopTypeId != 8) {
          // it is not restaurant
          whatsAppMessage = `Hello, I am ${this.state.customer.name}. My Yaadi number is ${yaadiId}. It contains ${numberOfCartProducts} item/s. Total amount is Rs. ${finalAmount} ${words}. Link for yaadi is www.meriyaadi.com/yaadilist/${yaadiUrl}`;
        } else {
          whatsAppMessage = `Hello, I am ${this.state.customer.name}. My Order number is ${yaadiId}. It contains ${numberOfCartProducts} item/s. Total amount is Rs. ${finalAmount}. Link for the order is www.meriyaadi.com/yaadilist/${yaadiUrl}`;
        }
        window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`; //2023

        // window.open(`https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`);
        // this.handleDeleteAllItems();
        this.moveItemsForRepeatUse();
        // The above method deletes all items from cart and from local storage
        // but before that it copies all items to another array named shopUrl+"_1"
        // in the local storage, added on 29.10.2020
        this.setState({
          // what to be shown after user presses button for WhatsApp
          action: "FINALSTEP", //"HOMEPAGE"
        });
      })
      .catch((err) => {
        console.log("error...2");
        this.setState({
          flagLoading: false,
        });
      });
  }; //postYaadiProductsToServer
  // Update cartItems list in the local storage
  updateLocalStorage = (cartItems) => {
    window.localStorage.setItem(this.shopUrl, JSON.stringify(cartItems));
  };
  // handleFavouriteClick = (productId) => {
  // };
  handleDeleteAllItems = () => {
    let cartItems = this.state.cartItems;
    let cart = [];
    // Delete only those items whose quantity is not 0
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].quantity == 0) {
        cart.push(cartItems[i]);
      }
    } //for
    this.setState({ cartItems: cart });
    this.updateLocalStorage(cart);
    this.calculateTotalAmount(cart);
    // delete from the local storage also
  };
  moveItemsForRepeatUse = () => {
    let cartItems = this.state.cartItems;
    this.setState({ cartItems: [] });
    // delete from the local storage also
    this.updateLocalStorage([]);
    this.calculateTotalAmount([]);
    window.localStorage.setItem(this.shopUrl + "_1", JSON.stringify(cartItems));
  };
  handleNoRepeatOrderClick = () => {
    this.setState({
      action: "VIEW",
      // userType: "REGULAR",
    });
  };
  handleRepeatOrderClick = () => {
    // copy items from localstorage-> shopUrl_1 to shopUrl
    let cart = JSON.parse(window.localStorage.getItem(this.shopUrl + "_1"));
    if (cart) {
      this.removeNoStockProducts(cart);
    }
    this.setState({
      action: "VIEW",
    });
  };

  unitToUnitDisplay(unitId, quantity) {
    let unitDisplay;
    if (unitId == 1) {
      // unit is numbers
      unitDisplay = quantity;
    } else if (unitId == 2 || unitId == 4) {
      // unit is gms
      unitDisplay = quantity * 1000;
      if (unitDisplay >= 1000) {
        unitDisplay /= 1000;
        unitDisplay = unitDisplay + " kg";
      } else {
        unitDisplay = unitDisplay + " gms";
      }
    } else if (unitId == 3 || unitId == 5) {
      // unit is kg
      unitDisplay = quantity * 1 + " kg";
    }
    return unitDisplay;
  }

  pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  // handlePlaceorderClick = () => {
  //   this.setState({
  //     action: "FINALYAADI",
  //   });
  // };
  //  handlePlaceorderClick = () => {

  // };

  createYaadiURL = () => {
    let name = this.state.customer.name.replace(" ", "_");
    let date = new Date();
    this.yaadiURL =
      name +
      "_" +
      date.getDate() +
      "_" +
      date.getMonth() +
      "_" +
      date.getHours() +
      "_" +
      date.getMinutes() +
      "_" +
      this.shopUrl;
  };
  componentDidMount() {
    // The above statement would be required for repeat order
    if (this.props.location.state) {
      // coming from repeat order

      this.setState({ isBilling: true });
      // this.shopUrl = this.props.location.state.shopUrl; // remove '/'
      this.shopUrl = this.props.location.pathname.substring(1); // remove '/'
      this.flagRepeatOrder = true;
    } else {
      //coming normally
      this.shopUrl = this.props.location.pathname.substring(1); // remove '/'

      this.flagRepeatOrder = false;
      // If the name contains _ (underscore), it may be a dealer's link
      if (this.shopUrl.indexOf("_") == -1) {
        // its not dealer
        this.dealer = "";
      } else {
        this.dealer = this.shopUrl.substring(this.shopUrl.indexOf("_") + 1);
        this.shopUrl = this.shopUrl.substring(0, this.shopUrl.indexOf("_"));
      }
    }
    this.shopUrl = this.shopUrl.toLowerCase();
    // first session check / creation
    this.setState({
      flagLoading: true,
    });

    axios
      .get(window.routerPrefix + "/shopType/welcome")
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        this.getShopInfo();
        this.getCustomerInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  } //  componentDidMount
  getShopInfo = () => {
    // Get data of shop by shopUrl
    let shopDetails;
    this.setState({
      flagLoading: true,
    });

    axios
      .get(window.routerPrefix + "/shop/byShopUrl/" + this.shopUrl)
      .then((res) => {
        shopDetails = res.data;
        if (!shopDetails) {
          //No shop
          this.props.history.push("/"); // remove the invalid shopUrl 2023
          this.setState({
            flagInvalidUrl: true,
            flagLoading: false,
            action: "HOMEPAGE",
          });
          return; // don't go ahead
        } else {
          // Check whether its admin acting as user
          shopDetails.showItemCount = 1; //2023 Always show item count, removed this colum from table
          let adminAsUser = window.localStorage.getItem("admin");
          if (!adminAsUser) {
            this.setState({ adminAsUser: false });
          } else if (adminAsUser == this.shopUrl) {
            this.setState({ adminAsUser: true });
          } else {
            this.setState({ adminAsUser: false });
          }
          // firstly get color-scheme of the shop as per shoptype
          this.setState({ colorScheme: shopDetails.colorScheme });
          document.title = "meriYaadi-" + shopDetails.shopUrl;
          this.setState({
            whatsAppStore: shopDetails.whatsApp,
          });
          // if dealer, then check its validity
          if (this.dealer) {
            if (!shopDetails.dealerFacility) {
              this.setState({ flagInvalidUrl: true, flagLoading: false });
              return;
            }
            // dealer facility is there, but link may be incorrect
            // get data of dealer from database
            axios
              .get(
                window.routerPrefix +
                  "/dealers/byLink/" +
                  shopDetails.shopUrl +
                  "/" +
                  this.dealer
              )
              .then((res1) => {
                if (res1.data.length === 0) {
                  //No such dealer
                  this.setState({
                    flagInvalidUrl: true,
                    flagLoading: false,
                  });
                  return; // don't go ahead
                }
                // this.whatsAppStore = res1.data[0].whatsApp;
                this.setState({
                  flagLoading: false,
                  whatsAppStore: shopDetails.whatsApp,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({ error: err, flagLoading: false }); // error is set
              });
          } //if
          else {
            // no dealer, valid shop
          }
          //added on 05.02.2025
          if (shopDetails.productImages == 0) {
            this.setState({ flagQuickMode: true });
          }
          this.setState({
            shopDetails: shopDetails,
            flagLoading: false,
          });
          if (!this.state.isBilling) {
            if (shopDetails.status == "not ready") {
              // shop just created, but not ready yet 2023
              this.handleProceedAsGuestClick();
            } else if (shopDetails.homePageFacility == 1) {
              this.setState({
                action: "ABOUTUS",
              });
            } else {
              this.setState({
                action: "CUSTOMERFORM",
              });
            }
          } else {
            this.handleProceedAsGuestClick();
          }

          // Now get settings of the shop
          this.getSettingsOfShop(shopDetails.shopUrl);
        } //else
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
    // create a cart in local storage of following type:
    // {
    //   cartItems: [
    //       { productId: "", name:"", weight:"", mrp:"", finalprice:"", quantity:"",
    //       inStock:""},
    //       { productId: "", name:"", weight:"", mrp:"", finalprice:"", quantity:"",
    //       inStock:""},
    //                   ]
    // }
    // first get information from local storage about the cart

    if (this.flagRepeatOrder) {
      this.setState({
        action: "CUSTOMERFORM",
      });
    }
  };
  getCustomerInfo = () => {
    let customer;
    let customerInfo = window.localStorage.getItem("customer");
    if (!customerInfo) {
      // no personal info, so user is new, allow him to be as Guest
      customer = null;
      this.setState({
        userType: "GUEST",
        customer: customer,
      });
    } else {
      customer = JSON.parse(window.localStorage.getItem("customer"));
      this.setState({
        userType: "REGULAR",
        customer: customer,
      });
    } //else
  };
  getSettingsOfShop = (shopUrl) => {
    let settings;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/setting/settings/" + shopUrl)
      .then((res) => {
        if (res.data.length === 0) {
          this.setState({
            flagLoading: false,
          });
          return; // don't go ahead
        } else {
          settings = res.data; // only one row
          // if not dealer then whatsApp number is from settings
          // otherwise already obtained from dealer database
          if (!this.dealer) {
            // this.whatsAppStore = settings.whatsApp;
            // this.setState({
            //   whatsAppStore: settings.whatsApp,
            // });
          }
          this.setState({
            settings: settings,
            flagLoading: false,
          });
        } //else
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  getProductsDetailsFromDatabase = () => {
    // let shopDetails = this.state.shopDetails;
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(window.routerPrefix + "/product/products/" + this.shopUrl),
        axios.get(window.routerPrefix + "/category/categories/" + this.shopUrl),
        axios.get(window.routerPrefix + "/brand/brands/" + this.shopUrl),
      ])
      .then(
        axios.spread((res2, res3, res4) => {
          // Bug reported by Mane of Puresattva, sorting by rating  19.08.2023
          let pList = res2.data;
          // sort by rating
          pList = pList.sort((a, b) => {
            if (a["rating"] < b["rating"]) {
              return 1;
            }
            if (a["rating"] > b["rating"]) {
              return -1;
            }
            return 0;
          });
          let dCount = 0;
          pList.forEach((e, index) => {
            if (e.finalPrice < e.mrp) dCount++;
          });

          this.setState({
            // filteredProducts: res2.data,
            // prdb: new ProductDB(res2.data), // use of class 25.05.2020
            // numberOfProducts: res2.data.length,
            filteredProducts: pList,
            prdb: new ProductDB(pList), // use of class 25.05.2020
            numberOfProducts: pList.length,
            categoryList: res3.data,
            brandList: res4.data,
            discountCnt: dCount,
          });
          let cart = window.localStorage.getItem(this.shopUrl);
          let cartItems;
          if (!cart) {
            window.localStorage.setItem(
              this.shopUrl,
              JSON.stringify(this.state.cartItems)
            );
            window.localStorage.setItem(
              "dateCreated" + this.shopUrl,
              JSON.stringify(new Date())
            );
          } else {
            cartItems = JSON.parse(window.localStorage.getItem(this.shopUrl)); //,JSON.stringify(new Date()));
            this.removeNoStockProducts(cartItems);
          }
          // now remove products (with no stock/deleted by admin) from the local storage

          this.setState({
            flagLoading: false,
          });
        })
      )

      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  render() {
    let { flagLoading } = this.state;
    let { action } = this.state;
    let { adminAsUser } = this.state;
    let { colorScheme } = this.state;
    let { shopDetails } = this.state;
    let { settings } = this.state;
    let { isBilling } = this.state;

    // let { flagSuspecious } = this.state;
    let { flagInvalidUrl } = this.state;
    // if (flagInvalidUrl || action === "HOMEPAGE") return <Redirect to="/" />;

    if (flagLoading)
      return (
        <div className="text-center mt-5">
          <BeatLoader size={48} color={colorScheme} flagLoading />
        </div>
      );
    let productsView, cartView, personalDetailsView;
    let navbar = (
      <NavBar
        userName={this.state.userName}
        userType={this.state.userType}
        totalAmount={this.state.totalAmount}
        numberOfCartProducts={this.state.numberOfCartProducts}
        onAdminLogOut={this.handleAdminLogOut}
        onAdminManageProducts={this.handleAdminManageProducts}
        onLogoClick={this.handleLogoClick}
        categoryList={this.state.categoryList}
        brandList={this.state.brandList}
        onCategoryClick={this.handleCategoryClick}
        onBrandClick={this.handleBrandClick}
        onSpecialOffersClick={this.handleSpecialOffersClick}
        searchText={this.state.searchText}
        onSearchTextChange={this.handleSearchTextChange}
        onSearchTextEnter={this.handleSearchTextEnter}
        onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
        onSearchTextFocus={this.handleSearchTextFocus}
        onSearchTextBlur={this.handleSearchTextBlur}
        onSearchButtonClick={this.handleSearchButtonClick}
        onSearchBoxProductClick={this.handleSearchBoxProductClick}
        onQuickModeSelection={this.handleQuickModeSelection}
        flagSearch={this.state.flagSearch}
        searchBy={this.state.searchBy}
        matchedFlag={this.state.matchedFlag}
        filteredProducts={this.state.filteredProducts}
        specialOffersList={this.state.specialOffersList}
        selectedCategoryName={this.selectedCategoryName}
        selectedBrandName={this.selectedBrandName}
        onCartClick={this.handleCartClick}
        onCancelClick={this.handleCancelClick}
        onShowAllClick={this.handleShowAllClick}
        shopUrl={shopDetails.shopUrl}
        shopTypeId={shopDetails.shopTypeId}
        flagCartAdd={this.state.flagCartAdd}
        prdb={this.state.prdb} // 28.07.2020
        flagQuickMode={this.state.flagQuickMode}
        flagCategoryLinkClick={this.state.flagCategoryLinkClick}
        flagBrandLinkClick={this.state.flagBrandLinkClick}
        onCategoryPanelClose={this.handleCategoryPanelClose}
        onBrandPanelClose={this.handleBrandPanelClose}
        onCategoryLinkClick={this.handleCategoryLinkClick}
        onBrandLinkClick={this.handleBrandLinkClick}
        status={shopDetails.status}
        productImages={shopDetails.productImages}
        showItemCount={shopDetails.showItemCount}
        // dummyPrice={this.state.settings.dummyPrice}
        colorScheme={colorScheme}
        isBilling={this.state.isBilling}
        flagOnlyAdded={this.state.flagOnlyAdded}
        flagOnlyFavourites={this.state.flagOnlyFavourites}
        flagOnlyDiscounted={this.state.flagOnlyDiscounted}
      />
    );
    productsView = (
      <ProductsDisplay
        colorScheme={colorScheme}
        filteredProducts={this.state.filteredProducts}
        onCategoryClick={this.handleCategoryClick}
        onBrandClick={this.handleBrandClick}
        specialOffersList={this.state.specialOffersList}
        onShowAllClick={this.handleShowAllClick}
        selectedCategoryName={this.selectedCategoryName}
        selectedBrandName={this.selectedBrandName}
        searchBy={this.state.searchBy}
        flagSearch={this.state.flagSearch}
        flagCategoryLinkClick={this.state.flagCategoryLinkClick}
        flagBrandLinkClick={this.state.flagBrandLinkClick}
        flagQuickMode={this.state.flagQuickMode}
        numberOfProducts={this.state.numberOfProducts}
        matchedFlag={this.state.matchedFlag}
        onAddToCartItemList={this.handleAddToCartItemList}
        cartItems={this.state.cartItems}
        shopTypeId={shopDetails.shopTypeId}
        // dummyPrice={this.state.settings.dummyPrice}
        productsPhoto={shopDetails.productsPhoto}
        informationPlace={shopDetails.informationPlace}
        languageChoice={shopDetails.languageChoice}
        onChangeProductQuantity={this.handleChangeProductQuantity}
      />
    );
    let productsQuickView = (
      <ProductsQuickDisplay
        colorScheme={colorScheme}
        filteredProducts={this.state.filteredProducts}
        onCategoryClick={this.handleCategoryClick}
        onBrandClick={this.handleBrandClick}
        specialOffersList={this.state.specialOffersList}
        onShowAllClick={this.handleShowAllClick}
        selectedCategoryName={this.selectedCategoryName}
        selectedBrandName={this.selectedBrandName}
        searchBy={this.state.searchBy}
        flagSearch={this.state.flagSearch}
        flagCategoryLinkClick={this.state.flagCategoryLinkClick}
        flagBrandLinkClick={this.state.flagBrandLinkClick}
        flagQuickMode={this.state.flagQuickMode}
        onHeaderClick={this.handleHeaderClick}
        numberOfProducts={this.state.numberOfProducts}
        matchedFlag={this.state.matchedFlag}
        onAddToCartItemList={this.handleAddToCartItemList}
        userType={this.state.userType}
        cartItems={this.state.cartItems}
        shopTypeId={shopDetails.shopTypeId}
        // dummyPrice={this.state.settings.dummyPrice}
        shopUrl={shopDetails.shopUrl}
        productsPhoto={shopDetails.productsPhoto}
        informationPlace={shopDetails.informationPlace}
        languageChoice={shopDetails.languageChoice}
        onChangeProductQuantity={this.handleChangeProductQuantity}
        onFilterClick={this.handleFilterClick}
        onOnlyFavouritesClick={this.handleOnlyFavouritesClick}
        onOnlyAddedClick={this.handleOnlyAddedClick}
        onOnlyDiscountedClick={this.handleOnlyDiscountedClick}
        flagOnlyAdded={this.state.flagOnlyAdded}
        flagOnlyFavourites={this.state.flagOnlyFavourites}
        flagOnlyDiscounted={this.state.flagOnlyDiscounted}
        discountCnt={this.state.discountCnt}
      />
    );
    cartView = (
      <ShoppingCartDisplay
        onBackClick={this.handleBackClick}
        cartItems={this.state.cartItems}
        onAddToCartItemList={this.handleAddToCartItemList}
        onChangeProductQuantity={this.handleChangeProductQuantity}
        onDeleteAllItems={this.handleDeleteAllItems}
        onDeleteFromCart={this.handleDeleteFromCart}
        totalAmount={this.state.totalAmount}
        numberOfCartProducts={this.state.numberOfCartProducts}
        onPlaceorderClick={this.handlePlaceorderClick}
        settings={settings}
        shopDetails={shopDetails}
        shopTypeId={shopDetails.shopTypeId}
        languageChoice={shopDetails.languageChoice}
        userType={this.state.userType}
        // dummyPrice={this.state.settings.dummyPrice}
        colorScheme={colorScheme}
        isBilling={isBilling}
      />
    );
    personalDetailsView = (
      <>
        {/* <NavBar1/> */}
        <CustomerForm
          colorScheme={colorScheme}
          customer={this.state.customer}
          userType={this.state.userType}
          adminAsUser={adminAsUser}
          onCustomerFormSubmit={this.handleCustomerFormSubmit}
          onProceedAsGuestClick={this.handleProceedAsGuestClick}
          shopDetails={shopDetails}
          settings={settings}
          numberOfCartProducts={this.state.numberOfCartProducts}
          totalAmount={this.state.totalAmount}
          onBackClick={this.handleBackClick}
          whatsAppStore={this.state.whatsAppStore}
        />
      </>
    );
    let loginView = <Login onAdminLogIn={this.handleAdminLogIn} />;
    let content;
    if (this.state.adminAction === "PRODUCTS") {
      content = <>{AdminActivityProduct}</>;
    } else if (action === "VIEW" && !this.state.flagQuickMode) {
      content = (
        <>
          {navbar}
          {/* <NavBar1/> */}
          {productsView}
        </>
      );
    } else if (action === "VIEW" && this.state.flagQuickMode) {
      content = (
        <>
          {navbar}
          {productsQuickView}
          {/* <NavBar1/> */}
          {/* <SearchList
            prdb={this.state.prdb}
            searchText={this.state.searchText}
            onSearchBoxProductClick={this.handleSearchBoxProductClick}
          /> */}
        </>
      );
    } else if (action === "CART") {
      content = cartView;
    } else if (action === "CUSTOMERFORM") {
      content = personalDetailsView;
    } else if (action === "HOMEPAGE") {
      content = <HomePage />;
    } else if (action === "REPEATORDER") {
      content = (
        <RepeatOrder
          shopTypeId={shopDetails.shopTypeId}
          shopUrl={this.shopUrl}
          languageChoice={shopDetails.languageChoice}
          cartItems={this.state.cartItems}
          prdb={this.state.prdb}
          colorScheme={colorScheme}
          onRepeatOrderClick={this.handleRepeatOrderClick}
          onNoRepeatOrderClick={this.handleNoRepeatOrderClick}
        />
      );
    } else if (action === "FINALYAADI") {
      content = (
        <FinalYaadiWhatsApp
          colorScheme={colorScheme}
          cartItems={this.state.cartItems}
          customer={this.state.customer}
          shopDetails={shopDetails}
          settings={settings}
          yaadiUrl={this.yaadiUrl}
          totalAmount={this.state.totalAmount}
          numberOfCartProducts={this.state.numberOfCartProducts}
          onBackClick={this.handleBackClick}
          onWhatsappButtonClick={this.handleWhatsappButtonClick}
          onPrintBillClick={this.handlePrintBillClick}
          shopTypeId={shopDetails.shopTypeId}
          languageChoice={shopDetails.languageChoice}
          dealer={this.dealer}
          whatsAppStore={this.state.whatsAppStore}
          dummyPrice={settings.dummyPrice}
          isBilling={isBilling}
          // onDeleteAllItems={this.handleDeleteAllItems}
        />
      );
    } //if
    else if (action === "ABOUTUS") {
      content = (
        <>
          <AboutUs
            onStartShoppingClick={this.handleStartShoppingClick}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
            settings={settings}
            dealer={this.dealer}
            whatsAppStore={this.state.whatsAppStore}
            adminAsUser={adminAsUser}
          />
        </>
      );
    } else if (action === "FINALSTEP") {
      content = (
        <>
          <ThanksPage shopName={settings.name} />
        </>
      );
    } else if (action === "LOGIN") {
      content = (
        <>
          {navbar}
          {/* {loginView} */}
        </>
      );
    } //if
    // return <div className="container-fluid container-main">{content}</div>;
    return <div className="container-fluid container-main">{content}</div>;
  }
} //class
export default Home;
