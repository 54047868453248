import React, { Component, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
export default function OwnerLogReport(props) {
  let grandTotal = 0;
  let [type, setType] = useState("");
  let [flagLoading, setFlagLoading] = useState("");
  let [error, setError] = useState("");
  let [date, setDate] = useState("");
  let [log, setLog] = useState("");
  let pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  function handleTypeSelection(value) {
    setType(value);
  }
  function handleShowHereClick() {
    getLastDate(type); // value is whichDate (today/yesterday)
  }
  function handleShowLogClick() {
    setFlagLoading(true);
    let requestUrl = "/files/downloadLogFile/" + type + "_meriyaadi.log";
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        setLog(res.data);
        // this.setState({
        //   log: res.data,
        // });
        // this.setState({
        //   message: "",
        // });
        setFlagLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoading(false);
      });
  }
  function handleDownloadClick() {
    let requestUrl = "/files/downloadLogFile/" + type + "_rjac.log";
    axios
      .get(window.routerPrefix + requestUrl)
      .then((res) => {
        let code = res.data;
        setFlagLoading(false);
        let fileName = res.headers["content-disposition"].split('"')[1];
        let type = "text/plain";
        const url = window.URL.createObjectURL(
          new Blob([code], {
            type: type,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove(); // you need to remove that elelment which is created before.
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoading(false);
      });
  }
  function getLastDate(type) {
    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/general/logDate/" + type)
      .then((res) => {
        let date = res.data;
        date = new Date(date);
        setDate(date);
        setFlagLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoading(false);
      });
  }

  let content = (
    <React.Fragment>
      {/* row starts */}
      <div className="column col-12 mt-5">
        <div className="form-heading">Log Files</div>
      </div>
      {/* row starts */}
      <div className="row">
        <div className="col-12 my-2 text-center ">
          <input
            type="radio"
            className="form-control-inline"
            name="type"
            value="info"
            onClick={() => handleTypeSelection("info")}
            onChange={() => handleTypeSelection("info")}
            checked={type === "info"}
          />
          &nbsp;Info
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="type"
              value="Error"
              onClick={() => handleTypeSelection("error")}
              onChange={() => handleTypeSelection("error")}
            />
            &nbsp;Error
          </div>
        </div>
      </div>

      {flagLoading && (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      <div className="col-12 text-center">
        <Link className="" to="#" onClick={handleShowHereClick}>
          last Date and Time
        </Link>
      </div>
      {date && (
        <div className="col-12 text-center">{date.toLocaleString()}</div>
      )}
      <div className="col-12 text-center">
        <button
          className="btn btn-primary"
          onClick={handleDownloadClick}
          disabled={type == ""}
        >
          &nbsp; Download &nbsp;
        </button>
        &nbsp; &nbsp;
        <button
          className="btn btn-primary"
          onClick={handleShowLogClick}
          disabled={type == ""}
        >
          &nbsp; Refresh content&nbsp;
        </button>
      </div>

      <div className="col-12 text-center"></div>
      {log && (
        <div className="col-12 " style={{ whiteSpace: "pre-wrap" }}>
          {log}
        </div>
      )}
      {/* {message && selectedMenu && (
          <div className="text-center mt-2" style={{ whiteSpace: "pre" }}>
            {message}
          </div>
        )} */}
    </React.Fragment>
  );
  if (error) {
    return <div>Error retriving logs </div>;
  } else {
    return (
      // <div className="container-fluid bg-blue">
      // <div className="row">
      <div className="container-fluid container-content-page p-0">
        {content}
      </div>
      // </div>
      // </div>
    );
  } //else
}