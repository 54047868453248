import React from "react";
import DatePicker from "react-datepicker";
class OwnerShopAdd extends React.Component {
  emptyShop = {
    shopId: "",
    name: "",
    shopUrl: "",
    title: "",
    shopType: "",
    shopTypeId: -1,
    owner: "",
    address: "",
    city: "",
    pinCode: "",
    whatsApp: "",
    mobile: "",
    landLine: "",
    emailId: "",
    aboutUs: "",
    validUpto: "",
    productsLimit: 50,
    productsPhoto: 1,
    productImages: 1,
    languageChoice: 1,
    informationPlace: 0,
    versionTypeId: 1,
    showItemCount: 1,
    dealerFacility: 0,
    homePagefacility: 0,
    paymentMessage: "",
    lastPaymentVerified: 0,
    status: 0,
    brandwiseDownload: 0,
    paymentDueDate: "",
  };
  errorShop = {
    name: { message: "", mxLen: 40, mnLen: 4, onlyDigits: false },
    shopUrl: { message: "", mxLen: 20, mnLen: 4, onlyDigits: false },
    title: { message: "", mxLen: 40, mnLen: 4, onlyDigits: false },
    shopType: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
    owner: { message: "", mxLen: 30, mnLen: 5, onlyDigits: false },
    address: { message: "", mxLen: 60, mnLen: 10, onlyDigits: false },
    city: { message: "", mxLen: 25, mnLen: 2, onlyDigits: false },
    pinCode: { message: "", mxLen: 6, mnLen: 6, onlyDigits: true },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    mobile: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    landLine: { message: "", mxLen: 11, mnLen: 11, onlyDigits: true },
    emailId: { message: "", mxLen: 40, mnLen: 6, onlyDigits: false },
    aboutUs: { message: "", mxLen: 800, mnLen: 50, onlyDigits: false },
    validUpto: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
    productsLimit: { message: "", mxLen: 10, mnLen: 1, onlyDigits: true },
    paymentMessage: { message: "", mxLen: 60, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let shop;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      shop = this.emptyShop;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      shop = this.props.shop;
    }
    this.state = {
      shop: shop,
      errorShop: this.errorShop,
      flagFormInvalid: flagFormInvalid,
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  handleChange = (event) => {
    let { errorShop } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        shop: { ...this.state.shop, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorShop[`${name}`].mxLen;
    let onlyDigits = errorShop[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorShop[`${name}`].message = message;
    this.setState({
      errorShop: errorShop,
      shop: { ...this.state.shop, [name]: value },
    });
    this.checkAllErrors(errorShop);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorShop } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorShop[`${name}`].mnLen;
      onlyDigits = errorShop[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      // Currently no checking
      value = event.target.selectedIndex;
      if (value == 0) {
        // message = name + requiredMessage;
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorShop[`${name}`].message = message;
    this.setState({ errorShop: errorShop });
    this.checkAllErrors(errorShop);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorShop = this.state.errorShop;
    errorShop[`${name}`].message = "";
    this.setState({ errorShop: errorShop });
    this.checkAllErrors(errorShop);
  };
  checkAllErrors = (errorShop) => {
    // let { errorShop } = this.state;
    let flagFormInvalid = false;
    for (let field in errorShop) {
      if (errorShop[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleSelectShopTypeChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    var selectedShopTypeId = optionElement.getAttribute("id");
    let shopType = event.target.value.trim();
    let shopTypeId = selectedShopTypeId;
    this.setState({
      shop: {
        ...this.state.shop,
        shopType: shopType,
        shopTypeId: shopTypeId,
      },
    });
  };
  handleRadioButtonClick = (event) => {
    this.setState({
      shop: { ...this.state.shop, [event.target.name]: event.target.value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onFormSubmit(this.state.shop); //this.state
  };
  handleFormCancelClick = () => {
    this.props.onFormCancelClick();
  };
  handleDatePickerClick = (date) => {
    this.setState({
      shop: { ...this.state.shop, paymentDueDate: date },
    });
  };
  render() {
    let { shopTypeList } = this.props;
    let { shop } = this.state;
    let { errorShop } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let heading;
    if (action === "ADD") {
      heading = "Add a Shop";
    } else if (action === "UPDATE") {
      heading = "Update the Shop";
    }
    return (
      <>
        <div className="form-heading column col-12">{heading}</div>
        <form className="text-thick bg-mycyan p-4" onSubmit={this.handleSubmit}>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Payment Message</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="text"
                className="form-control"
                name="paymentMessage"
                value={shop.paymentMessage}
                onChange={this.handleChange}
                placeholder="Enter payment reminder message"
              />
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Status</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="status"
                value="not ready"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.status == "not ready"}
              />
              &nbsp;Not ready&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="status"
                value="ready"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.status == "ready"}
              />
              &nbsp;Ready
              <input
                type="radio"
                className="form-control-inline"
                name="status"
                value="disabled"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.status == "disabled"}
              />
              &nbsp;Disabled
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Product Images?</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="productImages"
                value="0"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.productImages == 0}
              />
              &nbsp;No&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="productImages"
                value="1"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.productImages == 1}
              />
              &nbsp;Yes
            </div>
          </div>
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Last Payment Verified?</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="lastPaymentVerified"
                value="0"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.lastPaymentVerified == 0}
              />
              &nbsp;No&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="lastPaymentVerified"
                value="1"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.lastPaymentVerified == 1}
              />
              &nbsp;Yes
            </div>
          </div>
          {/* row ends */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Payment Due Date</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <DatePicker
                selected={new Date(shop.paymentDueDate)}
                // selectsStart
                // startDate={dateFrom}
                // endDate={dateTo}
                dateFormat="yyyy/MM/dd"
                // minDate={d.setDate(d.getDate() - 8)}
                onChange={(date) => this.handleDatePickerClick(date)}
              />
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Dealer Facility?</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="dealerFacility"
                value="0"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.dealerFacility == 0}
              />
              &nbsp;No&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="dealerFacility"
                value="1"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.dealerFacility == 1}
              />
              &nbsp;Yes
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Home Page Facility?</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="homePageFacility"
                value="0"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.homePageFacility == 0}
              />
              &nbsp;No&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="homePageFacility"
                value="1"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={shop.homePageFacility == 1}
              />
              &nbsp;Yes
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
            &nbsp; &nbsp;
            <button
              className="btn btn-primary"
              onClick={this.handleFormCancelClick}
              type="cancel"
            >
              Cancel
            </button>
          </div>
          <input type="hidden" name="shopId" value={shop.shopId} />
        </form>
      </>
    );
  }
}
export default OwnerShopAdd;
